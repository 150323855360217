import React, { useState, useEffect, useRef } from 'react';
import BrandMark from '../../../Components/SVG/BrandMark';
import AccountMenu from '../../../Components/AccountMenu/AccountMenu';
import { MAIN_CONFIG } from '../../../../config/main';
import './recommender-header.scss';
import ArrowDown from '../../../Components/SVG/ArrowDown';
import ChatList from '../../../Components/ChatList/ChatList';
import KaleidoscopeAPI from '../../../Core/KaleidoscopeAPI';
import { setConversationsData } from '../../../hooks/ConversationAction';
import Chat from '../../../Components/SVG/Chat';
import { useDispatch, useSelector } from 'react-redux';
import { reduxPage } from '../../../Core/store';
import '../DonorHeader/donor-header.scss';

const { USER_CONTEXT, PAGE_TYPES } = MAIN_CONFIG;

const RecommenderHeader = ({ data, currentUser, applicant_recommendations = {}, currentPath, herokuRole, setRoleSwitcherVisible, notifications, activeHeaderNav, userContext, pageType, setModalState = () => { } , pageData }) => {
	const { currentAccount, user, token } = data;
	const [showChatBox, setShowChatBox] = useState(false);
	const dispatch = useDispatch();
	const userConversations = useSelector(state => state.conversations)
	const unreadMessages = conversations && conversations.filter(conversation => conversation.messages.filter(message => !message.read_at).length > 0).length > 0
	const [conversations, setConversations] = useState(userConversations);
	const messageTrigger = useRef()

	const API = new KaleidoscopeAPI({});
	useEffect(() => {
		setConversations(userConversations)
	}, [userConversations])

	useEffect(() => {
		(async () => {
			// await fetchConversations()
		})();
	}, []);

	const fetchConversations = async () => {
		const response = await API.fetchConversations({ 'token': token })
		if (response) {
			setConversations(response)
			dispatch(setConversationsData(response))
		}
	}

	return (
		<div id='recommender-header__inner'>
			<div id='recommender-header__left'>
				<a href="/recommender">
					<BrandMark id='recommender-header__brand-mark' />
				</a>
				<nav>
					{pageType !== MAIN_CONFIG.PAGE_TYPES.RECOMMENDER.RECOMMENDATION &&
						<ul className='header__menu'>
							<li className="CTAGrey CTAWhite">
								<a href="/recommender">Dashboard</a>
							</li>
						</ul>
					}
				</nav>
			</div>

			{
				pageType === MAIN_CONFIG.PAGE_TYPES.RECOMMENDER.RECOMMENDATION ? (
					<>
						<p className='header__sholarship-name'>
							{applicant_recommendations.scholarship_name__c}
						</p>
						<div id='recommender-header__right'>
							<a href="/recommender"><span id='recommender-header-close' className='recommender-header__close'>close</span></a>
						</div>
					</>
				) : (
					<>
						<div id='donor-header__right' className="recommender-header">
							{/*<button
								id={'donor-header__chat-icon'}
								className='icon'
								ref={messageTrigger}
								onClick={() => setShowChatBox(!showChatBox)}
							>
								<Chat showIndicator={unreadMessages} ariaLabel="Chat Icon" ariaLabelDescription="Click here to chat with other users" />
								<ArrowDown
									id='donor-header__chat-arrow'
									className={showChatBox ? 'open' : ''}
									height={32}
									width={32}
								/>
							</button>
							<ChatList
								id='donor-header__chat-menu'
								className={showChatBox ? 'open' : ''}
								showChatBox={showChatBox}
								setModalState={setModalState}
								token={token}
								stateHandler={setShowChatBox}
								trigger={messageTrigger}
								currentUser={currentUser}
							/>*/}
						</div>
						<AccountMenu
							setRoleSwitcherVisible={setRoleSwitcherVisible}
							userContext={userContext}
							setShowChatBox={setShowChatBox}
							user={user}
							herokuRole={herokuRole}
							pageData = {pageData}
						/>
					</>
				)
			}
		</div>
	);
}
export default reduxPage(RecommenderHeader)
// export default (RecommenderHeader)

