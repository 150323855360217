import React, { useState, useEffect, useRef } from 'react';
const { v4: uuidv4 } = require('uuid');
import './field.scss';
import PlusButton from '../SVG/PlusButton';
import VideoButton from '../SVG/Video';
import AudioButton from '../SVG/Audio';
import Book from '../SVG/Book';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Calendar from '../SVG/Calendar';
import PaperClip from '../SVG/PaperClip';
import Paper from '../SVG/Paper';
import Close from '../SVG/Close';
import Search from '../SVG/Search';
import Select, { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import moment from 'moment'
import KaleidoscopeAPI from '../../../Modules/Core/KaleidoscopeAPI';
import { MAIN_CONFIG, audioType, currencySymbolList, documentTypeArray, supportedFileType, videoType } from '../../../config/main';
import ArrowDown from '../SVG/ArrowDown';
import Pencil from '../SVG/Pencil';
import slugify from 'slugify';
import PhoneInput from 'react-phone-input-2'
import SignaturePad from 'react-signature-canvas'
import { getImage } from "../../Utility/SVGImage";
import { toast } from 'react-toastify';
import SuggestiveField from "../SuggestiveField/SuggestiveField";
import { cloneDeep, isEmpty, isString } from 'lodash';
// import { env } from "../../Utility/EnvironmentConfig";
import 'react-phone-input-2/lib/style.css'
import Cleave from 'cleave.js/react';
import { formatCurrency, convertDataFormat, getAriaLabel, handleRediretInNewTab, validateEmailRegex } from '../../Utility/HelperFunctions';
import { MultiLevelSelect } from '../MultiLevelSelect/MultiLevelSelect';
import AddressSearchField from '../AddressSearchField/AddressSearchField';
import FieldCheckbox from '../../Components/SVG/FieldCheckbox';
import FlashMessage from '../../Components/FlashMessage/Message';
import RichTextEditor from "../RichTextEditor/RichTextEditor"
import sanitizeHtml from 'sanitize-html';
import { handleKeyDown } from '../../Utility/ApplicationFormUtility';
import { BlockBlobClient } from '@azure/storage-blob';
import { useDispatch } from 'react-redux';
import { uploadPercentage } from '../../hooks/currenttabIndexAction';
import UploadVideoButton from '../SVG/UploadVideoButton';
import UploadFileButton from '../SVG/UploadFileButton';
import { reduxPage } from '../../Core/store';
import Loader from '../Loader/Loader';
import AzureAddressSearchField from '../AzureAddressSearchField/AzureAddressSearchField';
import CTAAnchorTag from '../../Components/CTAAnchorTag/CTAAnchorTag'
import CTAImageTag from '../CTAImageTag/CTAImageTag';

/**
 *
 * @param {string} label
 * @param {string} placeholder
 * @param {string} id
 * @param {string} className
 * @param {string} toolTipMessage
 * @param {('text'|'color'|'password'|'file'|'email'|'search'|'textarea'|'date'|'select'|'multiselect'|'number'|'money')} type
 * @param {any} value
 * @param {number}charLimit
 * @param {number} wordLimit applicable to textarea
 * @param {boolean} disabled
 * @param {function} handleChange
 * @param {boolean} stylizePlaceholder
 * @param {object} options - Expects an object with value and label as properties
 * @param {boolean} noMargin - Removes the bottom margin on the field wrapper
 * @param {boolean} required
 * @param {boolean} isFutureDatePicker - Locks past dates when used with date picker
 * @param {string} instructions
 * @returns {JSX.Element}
 */
export function Field({
	label,
	ariaLabel = getAriaLabel(label),
	description,
	placeholder = '',
	id = '',
	className = '',
	labelClassName = '',
	toolTipMessage = '',
	type = 'text',
	isCustom,
	value = '',
	inputValue = '',
	charLimit = '',
	errorMsg = '',
	wordLimit,
	disabled = false,
	handleChange = () => { },
	onInputChange = () => { },
	handleDocumentDelete = () => { },
	stylizePlaceholder = false,
	options = [],
	uploadedDocument,
	has_document = false,
	documentHide = false,
	documentRemoveAlert = false,
	noMargin = false,
	required = false,
	requiredAsterisk = false,
	isFutureDatePicker,
	onbordingBirthdayDatefilter = false,
	instruction,
	onChangeRemoveSet = false,
	charcterCountLimitText,
	handlePasteText = () => { },
	maxLengthName = false,
	minNumber = false,
	maxNumber = false,
	max_video_length_in_seconds__c = 300,
	min_video_length_in_seconds__c = 0,
	selectIcon = false,
	removeCrossIcon = false,
	videoLable = 'Document',
	isPaste = false,
	isNotShowingFutureDate = false,
	minWordCountLongText = false,
	minDateFilter = null,
	maxDateFilter = null,
	maxLengthInput = null,
	autoSuggest = false,
	suggestionData = {},
	autoSuggestData = false,
	onSuggestiveSelect = () => { },
	onQuestionSuggestiveSelect = () => { },
	createPicklist = false,
	allQuestionsData = [],
	addressAutoSuggestData = false,
	handleAddressSelection = () => { },

	multiLevelPiclistData = () => { },
	multiLevelQeustion = "",
	isAgrementChange = () => { },
	onDocuSignShow = () => { },
	isEsignChange = () => { },
	onHandleBlur = () => { },
	onHandleKeyPress = () => { },
	setEmailValidateError = () => { },
	dateFormat = "",
	allowApplicantUploadsAfterDeadline = false,
	showWeight = false,
	alertForSelect = false,
	weight = {},
	setExpandCalender = () => { },
	fieldId = "",
	setOpenDate,
	openDate,
	key='',
	allowHtmlTags=false,
	isAzureDelete,
	currencyName = '',
	questionData
}) {

	max_video_length_in_seconds__c = max_video_length_in_seconds__c ? max_video_length_in_seconds__c : 300;
	min_video_length_in_seconds__c = min_video_length_in_seconds__c ? min_video_length_in_seconds__c : 0;
	const [emailValidError, setEmailValidateErr] = useState("");
	const API = new KaleidoscopeAPI({});
	const [fileSelected, setFileSelected] = useState(null);
	const [loading, setLoading] = useState(false);
	const fileInputRef = useRef(null);
	const dispatch = useDispatch()

	const AZURE_BASE_URL = process.env.NEXT_PUBLIC_BLOB_BASE_URL
	const CONTAINER_NAME = process.env.NEXT_PUBLIC_BLOB_STORAGE_CONTAINER
	const SAS_TOKEN = process.env.NEXT_PUBLIC_BLOB_SAS_TOKEN
	const getSymbol = () => {
		return currencySymbolList?.[`${currencyName}`]?.symbol || '$'
	}
    const currencySymbol = !currencyName ? '$' : getSymbol()
 	const checkVideoDuration = (val) => {
		return new Promise((resolve, reject) => {
			const video = document.createElement("video");

			// Bind an event handler for when the metadata is loaded
			video.onloadedmetadata = () => {
				// Access the duration once metadata is loaded
				const durationInSeconds = Math.floor(video.duration);
				if (durationInSeconds > max_video_length_in_seconds__c) {
					toast.error(
						<FlashMessage
							type="alert"
							message={`Video duration should be less than ${max_video_length_in_seconds__c} seconds.`}
						/>
					);
				} else if (durationInSeconds < min_video_length_in_seconds__c) {
					toast.error(
						<FlashMessage
							type="alert"
							message={`Video duration should be a minimum of ${min_video_length_in_seconds__c} seconds.`}
						/>
					);
				} else {
					beginUpload(val,durationInSeconds);
				}

				// Resolve the Promise with the duration
				resolve(durationInSeconds);

			};

			// Set the video source
			video.src = URL.createObjectURL(val);
			video.preload = "metadata"; // Load only metadata (including duration)

			// Handle any potential errors
			video.onerror = (error) => {
				console.error("Error loading video:", error);
				reject(error);
			};
		});
	};

	const uploadFileAzure = async (fileSelected) => {
		let uniqueId = uuidv4()
		const fileName = fileSelected.name.replace(/ /g, "-");
		let lastDotIndex = fileName.lastIndexOf(".");
		if (lastDotIndex !== -1) {
			var extension = fileName.slice(lastDotIndex);
			uniqueId = `${uniqueId}${extension}`;
		}

		setLoading(true)

		const url = `${AZURE_BASE_URL}/${CONTAINER_NAME}/${'imports'}/${'classic'}/${uniqueId}`;
		const blockBlobClient = new BlockBlobClient(`${url}?${SAS_TOKEN}`);
		let response = await blockBlobClient.uploadData(fileSelected, {
			maxSingleShotSize: 4 * 1024 * 1024,
			blobHTTPHeaders: { blobContentType: fileSelected.type },
			onProgress: (byte) => {
				setLoading(false)
				let percentage = ((byte.loadedBytes / fileSelected.size) * 100).toFixed(
					0
				);
				dispatch(uploadPercentage(percentage));
			},
		});
		return response;
	};
	let sigPad = {};
	/**
	 * Clear signature pad data.
	 */
	const clear = (e) => {
		e.preventDefault()
		if (!disabled) {
			updateValue('')
			sigPad.clear()
		}
	}
	const textFocusErrorRef = React.useRef()


	/**
	 * Fetch the value as Image from signature pad.
	 */
	const trim = (e) => {
		e.preventDefault()
		if (!disabled) {
			if (type === 'signature with timestamp') {
				var data = sigPad.getTrimmedCanvas().toDataURL('image/png');
				const canvas = document.createElement('canvas');
				const ctx = canvas.getContext('2d');
				var img = new Image();
				img.src = data;
				img.addEventListener('load', e => {
					ctx.drawImage(img, 0, 0, canvas.width, canvas.height - 20);
					ctx.fillStyle = "#000000";
					ctx.fillText(new Date().toUTCString(), 0, 145);
					var dataURL = canvas.toDataURL("image/png");
					updateValue(dataURL)
				});
			} else {
				updateValue(sigPad.getTrimmedCanvas().toDataURL('image/png'))
			}
		}
	}

	/**
	 * Clear Document Record.
	 */
	const deleteDocument = (name) => {
		// e.preventDefault();
		// handleDocumentDelete(id);
		isAzureDelete ? handleDocumentDelete(name) : handleDocumentDelete(id)
		if (!documentRemoveAlert) {
			setDocumentVal({});
		}
	}

	/**
	 * Returns the value to be used as the initial value.
	 * @returns {number|*}
	 */
	const getInitialValue = () => {
		switch (type) {
			case 'color':
				return removeHash(value);
			case "Date(Date-Month-Year)":
			case "Date(Month-Date-Year)":
			case "Date":
				value = convertDataFormat(value, type, true)
				return value
			case 'date':
				return typeof value === 'string' ? Date.parse(value) : value;
			case 'date-time':
				return value && new Date(value)
			default:
				return value;
		}
	}



	const removeHash = color => color ? color.replace('#', '') : '';
	const [showToolTip, setShowToolTip] = useState(false);
	const [documentVal, setDocumentVal] = useState(uploadedDocument);
	const [currentValue, setValue] = useState(getInitialValue());
	const [textareaCount, setTextareaCount] = useState(value ? value.length : 0);
	const [isFieldFocused, setIsFieldFocused] = useState(false)
	const isMulti = type === 'multiselect';

	const [startDate, setStartDate] = useState(new Date("2050/01/01"));
	const [endDate, setEndDate] = useState(new Date("1940/01/01"));
	const [maxDate, setMaxDate] = useState(new Date(maxDateFilter));
	const [minDate, setMinDate] = useState(new Date(minDateFilter));
	/**
	 * Select/Multiselect placeholder override.
	 * @param {object} props
	 * @returns {JSX.Element}
	 * @constructor
	 */
	const Placeholder = props => {
		const { isMulti, selectProps } = props;

		// Extracted ternary operation into an independent statement
		let placeholderText;
		if (isMulti) {
		  placeholderText = "Select all that apply";
		} else if (selectProps?.placeholder === 'Select Program') {
		  placeholderText = selectProps.placeholder;
		} else {
		  placeholderText = "Choose an option";
		}
		return (
		  <components.Placeholder {...props}>
			{placeholderText}
		  </components.Placeholder>
		);
	};

	useEffect(() => {
		setValue(getInitialValue())
	}, [value]);

	useEffect(() => {
		setDocumentVal(uploadedDocument)
	}, [uploadedDocument]);

	/**
	 * Select/Multiselect indicator override.
	 * @param {object} props
	 * @returns {JSX.Element}
	 * @constructor
	 */
	const DropdownIndicator = props => {
		return (
			<components.DropdownIndicator {...props}>

				<ArrowDown color={MAIN_CONFIG.COLORS.fillBlue} className={props.selectProps.menuIsOpen ? "" : ""} />
			</components.DropdownIndicator>
		);
	};


	const Control = ({ children, ...rest }) => {
		return (
			<components.Control {...rest}>
				{
					isCustom ? (
						<>
							<span className="custom-label-view" style={{ width: '95%' }}>{
								getControlData(children[0])}
							</span>
							{children[1]}
						</>
					) : children
				}
			</components.Control>
		)
	}

	const getControlData = (data) => {
		// var str = data && data.props && data.props.selectProps && data.props.selectProps.value;
		var val = data && data._owner && data._owner.memoizedProps && data._owner.memoizedProps.value && data._owner.memoizedProps.value.value;
		if (val) {
			var val1 = val.replace("option ", "");
			var str = val1.replace(", selected.", "");
			var first = str.substring(0, str.indexOf(","));
			var second = str.substring(str.indexOf(",") + 1, str.length);
			var html = <div style={{ height: '100%', display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
				<p>{first}</p>
				<p style={{ color: '#1a282c', fontSize: 12, opacity: '0.6' }}>{second}</p>
			</div>
			if (val != "") {
				if (data.props.selectProps.value.label) {
					data.props.selectProps.value.label = html;
				}
			}
		}
		return data;
	}




	/**
	 * Selected options custom design.
	 * @param {object} props
	 * @returns {JSX.Element}
	 * @constructor
	 */
	const formatOptionLabel = ({ value, label, school_city, school_street, school_postal_code, customAbbreviation }) => {
		return (
			<div style={{ display: "block" }}>
				<div>{label}</div>
				<div className="country_sub-details">
					{`${school_city}, ${school_street} ${school_postal_code}`}
				</div>
			</div>
		)
	}

	/**
	 * Selected value override.
	 * @param {object} props
	 * @returns {JSX.Element}
	 * @constructor
	 */
	const ValueContainer = props => {
		const { showCTA, ctaText, ctaAction } = currentValue;
		return (
			<components.ValueContainer {...props}>
				{showCTA &&
					<div className='CTAGrey field__select-edit-cta' onClick={ctaAction}>
						{ctaText}
						<Pencil className='field__select-edit-cta-icon' />
					</div>
				}
				{props.children}
			</components.ValueContainer>
		)
	}

	/**
	 * Select/Multiselect style override.
	 */
	const dropDownStyles = {
		container: () => ({
			border: errorMsg ? `1px solid ${MAIN_CONFIG.COLORS.fillRed}` : 'none',
		}),
		control: (styles, { isFocused }) => ({
			display: 'flex',
			marginTop: '8px',
			minHeight: '50px',
			backgroundColor: '#ffffff',
			border: isFocused ? `1px solid ${MAIN_CONFIG.COLORS.fillGreen}` : 'none',
			borderRadius: '4px',
			padding: isMulti ? '0 32px 0 4px' : '0 32px 0 4px',
			width: '100%',
			boxSizing: 'border-box',
			cursor: 'pointer',
			overflow: 'hidden',
			whiteSpace: 'nowrap'

		}),
		option: (styles, { isSelected, isFocused, isDisabled }) => ({
			...styles,
			backgroundColor: isFocused || isSelected ? MAIN_CONFIG.COLORS.fillHoverGray : 'rgba(26, 40, 44, 0.03)',
			color: MAIN_CONFIG.COLORS.fillBlack,
			cursor: 'pointer',
			display: 'flex',
			alignItem: 'center',
			justifyContent: 'flex-start',
			alignSelf: 'center'
		}),
		indicatorSeparator: styles => ({
			display: 'none'
		}),
		multiValue: (styles) => ({
			...styles,
			borderRadius: '8px',
			border: `1px solid ${MAIN_CONFIG.COLORS.fillBlack}`,
			padding: '8px 12px',
			backgroundColor: MAIN_CONFIG.COLORS.fillPink,
			marginRight: '8px',
		}),
		multiValueLabel: (styles) => ({
			...styles,
			borderRadius: '8px',
			paddingRight: '12px'
		}),
		multiValueRemove: (styles) => ({
			...styles,
			borderRadius: '8px'
		}),
	}

	const handleChangeMultilevel = (field, val) => {
		handleChange(val)
	}
	
	const handleBlur = async (e) => {
		e.preventDefault()
		let sanatizedValue = sanitizeHtml(currentValue, {
			allowedTags: sanitizeHtml.defaults.allowedTags.concat(MAIN_CONFIG.ALLOWED_TAGS),
			allowedAttributes: {
				a: MAIN_CONFIG.ALLOWED_ATTRIBUTES_FOR_ANCHOR,
				img: MAIN_CONFIG.ALLOWED_ATTRIBUTES_FOR_IMG,
				'*': MAIN_CONFIG.ALLOWED_ATTRIBUTES_FOR_REST,
				iframe: MAIN_CONFIG.ALLOWED_ATTRIBUTES_FOR_IFRAME,
			},
			allowedSchemes: MAIN_CONFIG.ALLOWED_SCHEMES,
			allowedSchemesByTag: {
				img: MAIN_CONFIG.ALLOWED_SCHEMES_FOR_IMG,
				a: MAIN_CONFIG.ALLOWED_SCHEMES_FOR_ANCHOR
			},
			allowedIframeHostnames: MAIN_CONFIG.ALLOWED_HOSTNAMES_FOR_IFRAME,
			allowVulnerableTags: true,
		})?.replace(/&amp;/g, '&')?.replace(/&lt;/g, "<")?.replace(/&gt;/g, ">")?.replace(/&quote;/g, `"`)
		onHandleBlur(allowHtmlTags ? sanatizedValue : currentValue)
		setIsFieldFocused(false)
	}
	const handleKeyPress = (e) => {
		let arr = ["<", ">"]
		if (arr.includes(e.key) && !allowHtmlTags) {
			e.preventDefault()
		}
		if (type === "text") {
			onHandleKeyPress(currentValue)
			setIsFieldFocused(false)
		}
	}

	const handleWheel = (e) => {
		// e.preventDefault()
		window.addEventListener("touchmove", function (e) { e.preventDefault(); }, { passive: false });
	}

	const handleFocus = (e) => {
		setIsFieldFocused(true);
		handleDoubleClick(e);
	}

	const handleDoubleClick = (e) => {
		e.target.onwheel = function (e) { e.preventDefault() }
	}

	const checkRestrictedKeywords = (val) => {
		if (isString(val)) {
			let checkTags = val.includes(">") || val.includes("<")
			if (!checkTags && val.includes('&') && !allowHtmlTags) {
				return false
			} else {
				return true
			}
		}
	}

	/**
	 * Updates the internal value and fires callback.
	 * @param {object} newValue
	 */
	const restrictKeyWord=(val)=>{
		if (checkRestrictedKeywords(val) && isString(val)) {
			let newStr = val.replace(/<>/g, "");
			return sanitizeHtml(newStr, { allowedTags: [] })
		}
	}

	const updateValue = (newValue) => {
		const elements = document?.getElementsByClassName('modal__inner')
		if (elements && type === 'select') {
			elements[0]?.parentElement?.focus()
		}
		setExpandCalender(false)
		let value = newValue || ''
		let count = value.length
		if (MAIN_CONFIG.FIELD_TYPES_CHECK?.includes(type)) {
			value = allowHtmlTags ? newValue : restrictKeyWord(newValue) 
		}
		setEmailValidateErr("")
		switch (type) {
			case 'color':
				setValue(value);
				handleChange(`#${value}`);
				break;
			case 'textarea':
				if (wordLimit || charLimit) {
					var limit = wordLimit || charLimit
					const words = value.match(/\S+/g)
					count = 0

					if (words && words.length <= limit) {
						value = allowHtmlTags ? newValue : restrictKeyWord(newValue) 
						count = words.length
						setValue(value);
						setTextareaCount(count);
					} else if (words && words.length > limit) {
						let splittedValue;
						splittedValue = allowHtmlTags ? newValue : restrictKeyWord(newValue)?.split(/\b/) 
						value = ''
						for (let i = 0, total = splittedValue.length; i < total; i++) {
							value += splittedValue[i]

							const currentWords = value.match(/\S+/g)
							count = currentWords.length

							if (count === limit) {
								break
							}
						}
					}
				}
				handleChange(value);
				break;
			case 'file':
				setValue(value.target.value);
				handleChange(value);
				break;
			case 'url':
				value = slugify(
					value,
					{
						strict: true,
					},
				);

				if (newValue.match(/\s$/) && !newValue.trim().match(/-$/)) {
					value += '-';
				}

				setValue(value);
				handleChange(value);

			case 'date':
				value = value.toString()
				setValue(value);
				handleChange(value);

			case 'date-time':
				setValue(value);
				// setOpenDate(value)
				handleChange(value);

			case 'Date(Month-Year)':
			case 'Date(Year)':
				setValue(value);
				handleChange(value);
			case 'email':
				if (type === "email") {
					var emailValidError;
					emailValidError = validateEmailRegex(value)
						? ""
						: "Email is not valid!";

					setEmailValidateErr(emailValidError);
					setEmailValidateError(emailValidError);
				}
			case 'select':
				if (alertForSelect) {
				} else {
					setValue(value);
				}
				handleChange(value);
			case 'Rich Text Area':
				if (typeof (value) === "string") {
					if (value.replace(/<(.|\n)*?>/g, '') === "") {
						let text = value.replace(/<(.|\n)*?>/g, '').trim()
						handleChange(text)
					} else {
						handleChange(value)
					}
				}

			default:
				if (!['date', 'select', 'Rich Text Area'].includes(type)) {
					setValue(value);
					handleChange(value);
				}

		}
	};

	/**
	 * fires callback for Document upload.
	 * @param {object} newValue
	 */
	const updateDocumentValue = (response, durationInSeconds = '') => {
		handleChange(response, durationInSeconds)
		const { url } = response._response.request;
		const {file_Name} = response._response;
		const document = {
			doc_url__c: url,
			doc_name__c: file_Name,
			...(durationInSeconds ? { video_duration__c: durationInSeconds } : {}),
		}
		if (!onChangeRemoveSet) {
			setDocumentVal(document)
		}
	}

	useEffect(() => {
		if (fileSelected) {
			fileInputRef.current.value = ''
		}
	}, [fileSelected])

	const onFileChange = (event, getUploadFileLabel) => {
		const file = event.target.files[0]
		const maxFileSize = 2147483648 //file size in bytes
		const fileName = file.name;
		const splitFileName = fileName.split('.');
		const fileExtension = splitFileName?.length > 1 ? splitFileName.pop().toLowerCase() : ""
		const skipFormat = ["avi", "mpeg", "mpeg2", "wmv", "flv"];

		if (file.size > maxFileSize) {
			toast.error(<FlashMessage type="alert" message={"Oops! Your file size is too large. Please upload a file smaller than 2GB."} />);
			setFileSelected(file);
			return;
		}
		if (!supportedFileType.includes(fileExtension)) {
			toast.error(<FlashMessage type="alert" message={"Sorry this file type is not supported."} />);
			setFileSelected(file);
			return;
		}

		switch (getUploadFileLabel) {
			case "Upload a Video":
				if (skipFormat.includes(fileExtension) || audioType.includes(fileExtension)) {
					beginUpload(file);
				} else if (videoType.includes(fileExtension)) {
					checkVideoDuration(file);
				} else {
					toast.error(<FlashMessage type="alert" message={"Please upload a video file."} />);
				}
					break;
				default:
					beginUpload(file);
			}
		setFileSelected(file);		
	};
	
	const beginUpload = async (file, durationInSeconds = '') => {
		let response = await uploadFileAzure(file)
		if (response) {
			response._response.file_Name = file?.name
			if (durationInSeconds) {
				response._response.video_duration__c = durationInSeconds
			}
			setFileSelected()
			updateDocumentValue(response, durationInSeconds)
			// fileInputRef.current.value = ''
			toast.success(<FlashMessage type="success" message={"File Uploaded Successfully!"} />);
		}
	}
	
		const handleButtonClick = () => {
			fileInputRef.current.click();
		  };

	const handleFilterMaxDate = () => {
		var date = moment().toDate();
		date.setFullYear(date.getFullYear() - 13)
		return date
	}

	const standardTypes = ['text', 'color', 'password', 'email', 'search', 'money', 'number', 'gpa', 'url'];

	const testIfOptionChecked = optionValue => {
		if ((currentValue !== 0 && !currentValue) || Array.isArray(currentValue) && currentValue.length === 0) {
			return false
		} else if (Array.isArray(currentValue) && currentValue.indexOf(optionValue) > -1) {
			return true
		} else if (currentValue.value === optionValue) {
			return true
		} else {
			return currentValue === optionValue
		}
	}
	const selectOption = selectedVal => {
		if (type === 'Checkbox') {
			let currentSelectedOption = currentValue ? currentValue : []
			if (currentSelectedOption.indexOf(selectedVal) === -1) {
				currentSelectedOption.push(selectedVal)
			} else {
				currentSelectedOption = currentSelectedOption.filter(option => option !== selectedVal)
			}
			setValue(Array.from(currentSelectedOption))
			handleChange(Array.from(currentSelectedOption))
		} else {
			setValue(selectedVal)
			handleChange(selectedVal)
		}
	}

	useEffect(() => {
		if (errorMsg && textFocusErrorRef && textFocusErrorRef.current) {
			textFocusErrorRef.current.focus()
		}
	}, [errorMsg])

	const Option = (props) => {
		const optionClass = props.isFocused ? 'focusedOption' : '';
		return (
			<div tabIndex={0} aria-label='dropdown options' className={optionClass} {...props.innerProps}>
				<components.Option {...props}>
					{selectIcon && getImage(props.value)} {props.children}
				</components.Option>
		     </div>
		);
	};

	let datype = "MMM d, yyyy";
	dateFormat = dateFormat ? dateFormat : type
	if (dateFormat === 'Date(Month-Year)') {
		datype = ['m', 'Y']
		placeholder = 'mm/yyyy'
	} else if (dateFormat === 'Date(Year)') {
		datype = ['Y']
		placeholder = 'yyyy'
	} else if (dateFormat === 'Date(Date-Month-Year)') {
		datype = ['d', 'm', 'Y']
		placeholder = 'dd/mm/yyyy'
	} else if (dateFormat === 'Date' || dateFormat === 'Date(Month-Date-Year)') {
		datype = ['m', 'd', 'Y']
		placeholder = 'mm/dd/yyyy'
	} else if (dateFormat === 'Date(Date-Month-Year-Time)') {
		datype = "MM/dd/yyyy h:mm aa",
			placeholder = 'mm/dd/yyyy'
	} else {
		datype = "MMM d, yyyy"
	}


	const handleOnInput = (e) => {
		const dataType = ['number', 'text']
		if (maxLengthInput && dataType?.includes(e.target.type) && e.target.value.length > maxLengthInput) {
			e.target.value = e.target.value.slice(0, maxLengthInput);
		}
	};
	const getUploadFileButton = () => {
		if(documentTypeArray.includes(videoLable)){
			return <UploadFileButton width={24} height={24} ariaLabel="Upload Document" ariaLabelDescription="" />
		}
		else if (videoLable == 'Video'){
			return <UploadVideoButton width={24} height={24} color={MAIN_CONFIG.COLORS.fillBlue} ariaLabel={ariaLabel} ariaLabelDescription="" />
		}
		return <AudioButton width={24} height={24} color={MAIN_CONFIG.COLORS.fillBlue} ariaLabel={ariaLabel} ariaLabelDescription="" />
	}

	const getUploadFileLabel = () => {
		if (documentTypeArray.includes(videoLable)) {
			return 'Upload a Document';
		} else if (videoLable === 'Video') {
			return 'Upload a Video';
		}
		return 'Upload an Audio File';
	}

	return (
		<>
		<div
			id={id}
			className={`
				field ${className}
				${stylizePlaceholder ? 'stylize-placeholder' : ''}
				${noMargin ? 'no-margin' : ''}
				${isFieldFocused ? 'is-focused' : ''}
				${errorMsg ? 'errored' : ''}
			`}
		>
			<label className={`CTAGrey ${labelClassName}`} htmlFor={id}>{label}
				{
					requiredAsterisk && <span className='required-asterisk-mark'>*</span>
				}
				{
					description && <p className='CTAGrey desc'>{description}</p>}
				{
					showWeight && <h1 style={{ fontSize: "14px", fontWeight: "bold" }} >Weight : {weight}%</h1>
				}
				{type === 'encrypted number' && questionData.encryptable__c &&
					<input
						className={`H6DesktopBlack field__text-field`}
						placeholder={placeholder}
						value={currentValue}
						type={'text'}
						step={type === 'gpa' ? 0.1 : ''}
						onChange={(e) => {
							const filteredValue = e.target.value.replace(/[^0-9]/g, '');
							updateValue(filteredValue);
						}}
						onInput={handleOnInput}
						disabled={disabled}
						required={required}
						requiredAsterisk={requiredAsterisk}
						onPaste={isPaste ? e => { e.preventDefault() } : null}
						max={maxNumber}
						ref={textFocusErrorRef}
						maxLength={maxLengthName}
						onFocus={(e) => handleFocus(e)}
						onBlur={(e) => handleBlur(e)}
						onKeyPress={(e) => handleKeyPress(e)}
						onDoubleClick={(e) => handleDoubleClick(e)}
						onWheel={(e) => handleWheel(e)}
						onScroll={(e) => handleWheel(e)}
						aria-label={ariaLabel}
						min={minNumber}
						id={fieldId}
					/>
				}

				{!autoSuggest && !addressAutoSuggestData && standardTypes.includes(type) &&
					<input
						className={`H6DesktopBlack field__text-field ${type === 'color' || ['money', 'gpa', 'search'].includes(type) ? type : ''}`}
						placeholder={placeholder}
						value={type === 'color' ? removeHash(currentValue) : type === 'money' ? formatCurrency(currentValue, currencyName) : currentValue}
						type={type === 'color' ? 'text' : ['gpa'].includes(type) ? 'number' : type}
						step={type === 'gpa' ? 0.1 : ''}
						onChange={(e) => updateValue(e.target.value)}
						onInput={handleOnInput}
						disabled={disabled}
						required={required}
						requiredAsterisk={requiredAsterisk}
						onPaste={isPaste ? e => { e.preventDefault() } : null}
						max={maxNumber}
						ref={textFocusErrorRef}
						maxLength={maxLengthName}
						onFocus={(e) => handleFocus(e)}
						onBlur={(e) => handleBlur(e)}
						onKeyPress={(e) => handleKeyPress(e)}
						onDoubleClick={(e) => handleDoubleClick(e)}
						onWheel={(e) => handleWheel(e)}
						onScroll={(e) => handleWheel(e)}
						aria-label={ariaLabel}
						min={minNumber}
						id={fieldId}
					/>
				}
				{
					standardTypes.includes("email") && !errorMsg &&
					<>
						{emailValidError ? emailValidError : null}
					</>
				}

				{
					type === 'textarea' &&
					<>
						<textarea
							placeholder={placeholder}
							onChange={(e) => updateValue(e.target.value)}
							disabled={disabled}
							onPaste={handlePasteText ? handlePasteText : null}
							required={required}
							requiredAsterisk={requiredAsterisk}
							value={currentValue}
							onKeyPress={(e) => handleKeyPress(e)}
							// minLength={minWordCountLongText ? minWordCountLongText : false}
							// maxLength={((minWordCountLongText && value ? value.split(' ').length : value ? value.length : 0) === (charLimit || wordLimit))}
							onFocus={() => setIsFieldFocused(true)}
							onBlur={(e) => handleBlur(e)}
							aria-label={ariaLabel}
							id={fieldId}
							key={key}
						/>
						{(charLimit || wordLimit) && minWordCountLongText && <div className='field__counter'>{minWordCountLongText && value ? value.match(/\S+/g) ? value.match(/\S+/g).length : 0 : 0}/{charLimit || wordLimit} {!minWordCountLongText && charLimit ? 'WORDS' : 'WORDS'}</div>}
					</>
				}



				{
					!documentHide && has_document && isEmpty(documentVal) && type !== 'Document' &&
					<div className="field__azure-upload-field">
						<input type='file' disabled={disabled} onChange={(e)=>onFileChange(e,getUploadFileLabel())} ref={fileInputRef} style={{ display: 'none' }}/>
						<button type='button' className='field__azure-upload-button-with-label' onClick={() => handleButtonClick()} aria-label={`${getUploadFileLabel()}`}>
							<div className='field__azure-upload-button'>{getUploadFileButton()}</div>
							<div className="field__azure-upload-label">{getUploadFileLabel()}</div>
						</button>
					</div>
				}

				{
					!documentHide && type === 'Document' &&
					<div className="field__azure-upload-field">
							{
								documentVal && (documentVal.doc_url__c) ? null : (
									<>
									 <input type='file' disabled={disabled} onChange={(e)=>onFileChange(e,getUploadFileLabel())} ref={fileInputRef} style={{ display: 'none' }}/>
									<button type='button' className='field__azure-upload-button-with-label' onClick={() => handleButtonClick()} aria-label={`${getUploadFileLabel()}`}>
										<div className='field__azure-upload-button'>{getUploadFileButton()}</div>
										<div className="field__azure-upload-label">{getUploadFileLabel()}</div>
									</button>
									</>
								)
							}
					</div>
				}

				{
					type === "Hover Dependent Picklist" &&
					<MultiLevelSelect
						config={multiLevelPiclistData()}
						controlFunc={handleChangeMultilevel}
						fieldObj={multiLevelQeustion}
					/>
				}


				{type === 'file' &&

					<input
						className={`H6DesktopBlack field__text-field`}
						placeholder={placeholder}
						value={currentValue}
						type={type}
						onChange={(e) => updateValue(e)}
						disabled={disabled}
						required={required}
						requiredAsterisk={requiredAsterisk}
						aria-label={ariaLabel}
						accept=".csv"
						id={fieldId}
					/>
				}

				{type === "Rich Text Area" &&

					<RichTextEditor value={value} onChange={(e) => updateValue(e)} />

				}

				{
					['Radio Button', 'Checkbox'].indexOf(type) > -1 && (
						<div className="radio__text-field">
							{
								options.map((option, i) => {
									return (
										<div
											className="filter-selector-item__dropdown-option"
											key={Math.random()}
										>
											<label className="option-checkbox" tabIndex={0} onKeyDown={(e)=>handleKeyDown(e,()=>selectOption(option.value))}>
												<span className="checkbox__input">
													<input
														type={type === 'Checkbox' ? 'checkbox' : 'radio'}
														className="checkbox-circle"
														onChange={() => {
															selectOption(option.value)
														}}
														checked={testIfOptionChecked(option.value)}
														disabled={disabled}
														id={option.label}
														aria-labelledby={option.label}
														tabIndex={-1}
													/>
													<span className="checkbox__control">
														<FieldCheckbox id={Math.random()} />
													</span>
												</span>
												<span className="checkbox-label BodyDefaultBoldBlack">
													{option.label}
												</span>
											</label>
										</div>
									)
								})
							}
						</div>
					)
				}
				{
					type === "Agreement PopUp" &&
					<div className="radio-select mediation no-border no-padding agreement-popup-section">
						<div className="checkbox-group form-group">
							<label key='agree' className="option-checkbox" tabIndex={0} onKeyDown={(e)=>handleKeyDown(e,()=>isAgrementChange(e, multiLevelQeustion))}>
								<span className="checkbox__input">
									<input
										className="agreement-input form-checkbox"
										name={multiLevelQeustion.name}
										onChange={(e) => isAgrementChange(e, multiLevelQeustion)}
										value='agree'
										checked={((multiLevelQeustion.answer && multiLevelQeustion.answer != 'false') ? true : false)}
										type='checkbox'
										aria-label={ariaLabel}
										id={fieldId}
									// disabled={((multiLevelQeustion.answer && multiLevelQeustion.answer != 'false') ? true : false)}
									/>

									<span className="checkbox__control">
										<FieldCheckbox />
									</span>
								</span>
								<span className="checkbox-label BodyDefaultBoldBlack" style={{ pointerEvents: multiLevelQeustion.answer != 'false' ? "none" : "auto" }}>
									Please click <em name={multiLevelQeustion.name} className="agreement-label" onClick={(e) => isAgrementChange(e, multiLevelQeustion)}>here </em> to read and accept
								</span>
							</label>
						</div>
					</div>
				}

				{
					type === "Esign" &&
					<div className="radio-select mediation no-border no-padding agreement-popup-section">
						<div className="checkbox-group form-group">
							<label key='agree'
								//
								className="form-label radio-inline CTABlack">
								<input
									className="agreement-input form-checkbox"
									id="docusign-elmnt"
									name={multiLevelQeustion.name}
									onChange={multiLevelQeustion.answer ? console.log('') : (e) => isEsignChange(e, multiLevelQeustion)}
									// onChange={(e) => isEsignChange(e, multiLevelQeustion)}
									value='singed'
									disabled={multiLevelQeustion.read_only}
									checked={multiLevelQeustion.answer}
									type='checkbox'
									aria-label={ariaLabel}
								/>
								<span className="read-and-accept-message">
									&nbsp;Sign Document
								</span>
							</label>
						</div>
					</div>
				}

				{
					type === "Docusign" &&
					<div>
						<div className="radio-select mediation no-border no-padding agreement-popup-section">
							<div className="checkbox-group form-group">
								<label key='agree'
									className="form-label radio-inline CTABlack">
									<input
										className="agreement-input form-checkbox"
										name={multiLevelQeustion.id}
										//  onChange={(e) => isAgrementChange(e, multiLevelQeustion)}

										onChange={multiLevelQeustion.answer ? console.log('') : onDocuSignShow}
										value='singed'
										id="docusign-elmnt"
										//  disabled={multiLevelQeustion.read_only}
										checked={multiLevelQeustion.answer}
										type='checkbox'
										aria-label={ariaLabel}
									/>
									<span className="read-and-accept-message">
										&nbsp;Sign Agreement
									</span>
								</label>
							</div>
						</div>
					</div>
				}



				{
					type === 'color' &&
					<>
						<div className='field__color-hash'>#</div>
						<div className='field__color-picker-outer'>
							<input
								className='field__color-picker'
								type='color'
								onChange={e => updateValue(removeHash(e.target.value))}
								disabled={disabled}
								value={`#${currentValue}`}
								aria-label={ariaLabel}
							/>
						</div>
					</>
				}

				{
					type === 'money' && currencySymbol &&
					<div className = { `field__dollar-sign ${(!documentHide && has_document && isEmpty(documentVal) && type !== 'Document') || (!documentHide && type === 'Document') ? 'field__dollar-sign__Document' : '' }`}>{currencySymbol}</div>
				}                                                                                                        

				{
					type === 'gpa' &&
					<Book className='field__book-icon' />
				}

				{
					type === 'file' &&
					<PaperClip className='field__file-icon' />
				}

				{
					type === 'search' &&
					<Search className='field__search-icon' ariaLabel="Search Icon" />
				}

				{
					(type === 'signature' || type === 'signature with timestamp') &&
					<div className='field__signature-container'>
						<div className='field__signpad-container'>
							<SignaturePad
								canvasProps={{ className: 'sigPad' }}
								onEnd={trim}
								ref={(ref) => { sigPad = ref }}
							/>

							<div className="field__signpad-button-container">
								<button className='signature__action-buttons' onClick={clear}>Clear</button>
							</div>
						</div>
						{currentValue
							? <CTAImageTag
								className='signature__preview-img'
								data={currentValue} />
							: null
						}
					</div>
				}

				{
					type === 'phone' &&
					<>
						<PhoneInput
							country={'us'}
							value={currentValue}
							onChange={phone => updateValue(phone)}
							disabled={disabled}
							inputProps={{
								name: id,
								required: required,
								requiredAsterisk: requiredAsterisk,
								autoFocus: false,
								className: 'H6DesktopBlack field__tel-field',
							}}
							aria-label={ariaLabel}
						/>
					</>
				}

				{
					type === 'date' &&
					<>
						<div className={`parent-date-picker-field ${onbordingBirthdayDatefilter && "onbording-birthday-date-picker"}`}>
							<DatePicker
								className={`H6DesktopBlack field__text-field `}
								placeholder={placeholder}
								selected={currentValue}
								onChange={updateValue}
								disabled={disabled}
								showYearDropdown={true}
								peekNextMonth={true}
								showMonthDropdown={true}
								scrollableYearDropdown={true}
								dateFormat={datype}
								yearDropdownItemNumber={80}
								value={currentValue}
								maxDate={(isNotShowingFutureDate ? handleFilterMaxDate() : maxDateFilter ? maxDate : startDate)}
								minDate={(isFutureDatePicker ? isFutureDatePicker : minDateFilter ? minDate : endDate)}
								required={required}
								requiredAsterisk={requiredAsterisk}
								aria-label={ariaLabel}
								onFocus={() => setExpandCalender(true)}
								onClickOutside={() => setExpandCalender(false)}
								id={fieldId}
							/>
							<Calendar className='field__calendar-icon' />
						</div>
					</>
				}
				{
					type === 'date-time' &&
					<>
						<div className={`parent-date-picker-field ${onbordingBirthdayDatefilter && "onbording-birthday-date-picker"}`}>
							<DatePicker
								className={`H6DesktopBlack field__text-field `}
								placeholder={placeholder}
								selected={currentValue}
								onChange={updateValue}
								showTimeSelect
								value={currentValue}
								dateFormat="MMMM d, yyyy h:mm aa"
								maxDate={(isNotShowingFutureDate ? handleFilterMaxDate() : maxDateFilter ? maxDate : startDate)}
								minDate={(isFutureDatePicker ? isFutureDatePicker : minDateFilter ? minDate : endDate)}
								yearDropdownItemNumber={80}
								showYearDropdown={true}
								peekNextMonth={true}
								disabled={disabled}
								showMonthDropdown={true}
								scrollableYearDropdown={true}
								required={required}
								requiredAsterisk={requiredAsterisk}
								aria-label={ariaLabel}
								onFocus={() => setExpandCalender(true)}
								onClickOutside={() => setExpandCalender(false)}
								id={fieldId}
							/>
							<Calendar className='field__calendar-icon' />
						</div>
					</>
				}
				{
					(['Date(Month-Year)', 'Date(Year)', "Date(Date-Month-Year)", "Date(Month-Date-Year)", "Date"].indexOf(type) > -1) &&
					<>
						<div className={`parent-date-picker-field`}>
							<Cleave
								className={`H6DesktopBlack field__text-field `}
								placeholder={placeholder}
								options={{ date: true, datePattern: datype }}
								onChange={e => updateValue(e.target.value)}
								value={currentValue}
								disabled={disabled}
								readOnly={disabled ? true : false}
								required={required}
								requiredAsterisk={requiredAsterisk}
								dateMin={minDate}
								dateMax={maxDate}
								aria-label={ariaLabel}
							/>
							{/* <Calendar className='field__calendar-icon' /> */}
						</div>
					</>
				}

				{
					(type === 'select' || isMulti) && !createPicklist &&
					<>
						<Select
							options={options}
							placeholder={placeholder}
							styles={dropDownStyles}
							components={{ Placeholder, DropdownIndicator, Option }}
							formatOptionLabel={label === 'School' ? formatOptionLabel : null}
							className={`H6DesktopBlack dropdown-with-border ${disabled && 'multi-select-dropdown-option-disabled'} remove-error-alert-space`}
							isMulti={isMulti}
							closeMenuOnSelect={!isMulti ? true : false}
							closeMenuOnScroll={true}
							onChange={updateValue}
							value={currentValue?.label===''?null:currentValue}
							// required={required}
							requiredAsterisk={requiredAsterisk}
							isDisabled={disabled}
							aria-label={ariaLabel}

						/>
						{!disabled && (
							<input
								tabIndex={-1}
								autoComplete="off"
								style={{ opacity: 0, height: 0, display:'none'}}
								value={currentValue}
								required={required}
								disabled={disabled}
							/>
						)}
					</>
				}
				{
					(type === 'select' || isMulti) && createPicklist && !suggestionData.auto_suggest_restriction__c &&
					<CreatableSelect
						isClearable
						options={options}
						placeholder={placeholder}
						styles={dropDownStyles}
						components={{ Control, Placeholder, DropdownIndicator, Option }}
						formatOptionLabel={label === 'School' ? formatOptionLabel : null}
						className='H6DesktopBlack dropdown-with-border'
						isMulti={isMulti}
						onChange={updateValue}
						value={currentValue}
						required={required}
						requiredAsterisk={requiredAsterisk}
						isDisabled={disabled}
						aria-label={ariaLabel}
					/>
				}
				{
					(type === 'select' || isMulti) && createPicklist && suggestionData.auto_suggest_restriction__c &&
					<Select
						options={options}
						placeholder={placeholder}
						styles={dropDownStyles}
						components={{ Placeholder, DropdownIndicator, Option }}
						formatOptionLabel={label === 'School' ? formatOptionLabel : null}
						className={`H6DesktopBlack dropdown-with-border ${disabled && 'multi-select-dropdown-option-disabled'} remove-error-alert-space`}
						isMulti={isMulti}
						closeMenuOnSelect={!isMulti ? true : false}
						closeMenuOnScroll={true}
						onChange={updateValue}
						value={currentValue}
						required={required}
						requiredAsterisk={requiredAsterisk}
						isDisabled={disabled}
						aria-label={ariaLabel}

					/>
				}
				{
					autoSuggest &&
					<SuggestiveField
						inputType={suggestionData.data_type__c || suggestionData.application_scores_question_type__c}
						// noDesc={hideDesc}
						title={suggestionData.label__c}
						desc={suggestionData.description__c}
						suggestionData={suggestionData}
						name={suggestionData.name}
						auto_suggest_restriction={suggestionData.auto_suggest_restriction__c}
						content={suggestionData.answer || suggestionData.answer__c}
						// error={suggestionData.name}
						config_type={suggestionData.config_type__c}
						autoSuggest={suggestionData.auto_suggest__c}
						autoSuggestData={suggestionData.auto_suggest_data__c}
						salesforce_field_value__c={suggestionData.salesforce_field_value__c}
						onSuggestiveSelect={onSuggestiveSelect}
						onQuestionSuggestiveSelect={onQuestionSuggestiveSelect}
						// read_only={isReadOnly(suggestionData)}
						suggestInputs={suggestionData}
						allQuestionsData={allQuestionsData}
						placeholder={(suggestionData.config_type__c === 'Question') ? '' : suggestionData.label__c}
						isDisabled={disabled}
						checkRestrictedKeywords={checkRestrictedKeywords}
					/>

				}
				{
					addressAutoSuggestData &&
					<AzureAddressSearchField
						fieldObj={suggestionData}
						handleAddressSelection={handleAddressSelection}
						value={suggestionData.answer || suggestionData.answer__c}
						isDisabled={disabled}
					/>
				}
				{
					errorMsg && (
						<p className="invalid field__error-msg">{errorMsg}</p>
					)
				}

				{
					documentVal && (documentVal.doc_url__c) && (
						<div className="recommendation-form__uploaded-document-container">
							 <CTAAnchorTag handleClick={() => handleRediretInNewTab(documentVal.doc_url__c)} className="CTAGrey uploaded-document__label">{documentVal.doc_name__c}</CTAAnchorTag>
							 {!removeCrossIcon && !disabled && <button onClick={()=>deleteDocument(documentVal.doc_url__c)}className="delete-document__cta" aria-label="Remove"><Close color={MAIN_CONFIG.COLORS.fillGreen} ariaLabel="Remove Button" ariaLabelDescription='' tabIndex={-1}/></button>}
							 </div>
					)
				}

				{
					toolTipMessage &&
					<>
						<a href="javascript:void(0)" className='field__tip-icon' onClick={() => setShowToolTip(!showToolTip)} aria-label={showToolTip ? `Tool tip button active` : `Tool tip button inactive`}>?</a>
						<div className={`field__tip-message ${showToolTip ? 'show' : ''}`}>{toolTipMessage}</div>
					</>
				}

				{
					instruction &&
					<small className="field__instruction">
						{instruction}
					</small>
				}

			</label>
		</div >
			<Loader loading={loading}/>
		</>
	);
};

export default reduxPage(Field)