import { MAIN_CONFIG } from './main';

export const API_CONFIG = {

    // BASE_API_URL: 'https://api-dev.mykaleidoscope.com',
    BASE_API_URL: process.env.NEXT_PUBLIC_BASE_API_URL,
    ACTION_CABLE_URL: process.env.NEXT_PUBLIC_ACTION_CABLE_URL,

    API_VERSION_PATH: '/api/v1',
    END_POINTS: {
        AUTH: {
            SIGN_IN: () => '/sign-in',
            CREATE_OTP: () => '/create-otp',
            AUTHORIZE_OTP: () => '/otp-sign-in',
            SIGN_UP: () => "/register",
            SIGN_OUT: () => "/sign-out",
            SIGN_UP_INSTITUTION: () => "/institutions",
            SIGN_UP_INSTITUTION_SEARCH: (val) => `/institutions?search_with=${val}`,
            SIGN_UP_INSTITUTION_NAME_WITH_PARAMS: (searchTerm, auto_suggest_data, suggestion_for_gradebook) => `/auto_suggest/institutions?name=${searchTerm}&auto_suggest_data=${auto_suggest_data}&suggestion_for_gradebook=${suggestion_for_gradebook}`,
            SPONSOR_SIGN_UP: () => "/register/client",
            SENDPASSWORDLINK: () => "/password",
            RESETPASSWORD: () => "/password",
            CHECKRESETPASSWORDLINK: () => "/password/check_recovery_link",
            CHECKUSER: () => "/user-exists",
            SSO: {
                GET_CODE: () => '/create-secure-id',
                FETCH_TOKEN: () => '/secure-id-sign-in'
            },
            GOOGLELOGIN: () => '/google-auth-sign-in',
            SAML: {
                FETCH_TOKEN: () => '/secure-id-sign-in',
                FETCH_URL: (accountId) => `/sso/init?idp_uniq_id=${accountId}`
            },
            PARENT_CONSENT_FOR_SCHOLARSHIP: () => `/ask_parent_consent`,
            PARENT_CONSENT_FORM: () => `/create_parent_consent`,
            PROMOTE_AS_APPLICANT: () => `/user/promote_as_applicant`

            // SALES_FORCE_CALLBACK: () => '/users/login_via_salesforce'
        },
        CONVERSATIONS: {
            GET_ALL_CONVERSATION: () => '/conversations',
            UPDATE_CONVERASATION: (conversationId) => `/conversations/${conversationId}`,
            GET_NEW_CONVERSATION_CONTACT: () => '/conversations/search',
            GET_ALL_MESSAGE: () => '/messages',
            CREATE_MESSAGE: (conversationId) => `/conversations/${conversationId}/messages`
        },
        NOTIFICATIONS_SETTING: {
            GET_ALL: () => '/notification_setting',
            UPDATE: () => '/notification_setting'
        },
        NOTIFICATIONS: {
            GET_ALL: () => '/notifications',
            TOUCH_ALL: () => '/notifications/touch_all'
        },
        INSTITUTE: {
            FETCH_ALL: () => '/institutions'
        },
        ACCOUNT: {
            FETCH_DETAILS: (accountId) => `/accounts/${accountId}`
        },
        DONORS: {
            ACCOUNTS: {
                GET_ALL: () => '/clients/accounts',
                UPDATE: (accountId) => `/clients/accounts/${accountId}`,
                REMOVE_DOCUMENT: (accountId, documentId) => `/clients/accounts/${accountId}/document/${documentId}`,
                NPS_SCORE: (accountId) => `/clients/accounts/${accountId}/nps_score`,
                NOTIFY_ACCOUNT_MANAGER: (accountId) => `/clients/accounts/${accountId}/notify_manager`
            },
            ACCOUNT_STATS: {
                GET_ALL: (accountId) => `/clients/accounts/${accountId}/dashboard`,
                ADD_REMOVE_TILES: (accountId) => `/clients/accounts/${accountId}/dashboard`,
            },
            SCHOLARSHIPS: {
                GET_ALL: (accountId) => `/clients/accounts/${accountId}/scholarships`,
                GET_BUILDER_TOOLTIPS: () => `/builder_tooltips`,
                CREATE: (accountId) => `/clients/accounts/${accountId}/scholarships`,
                CREATE_EXPORT_PDF: (accountId) => `/clients/accounts/${accountId}/export_pdf`,
                CREATE_EXPORT_XLS: (accountId) => `/clients/accounts/${accountId}/export_xls`,
                QUALIFYING_SCHOLARSHIP_EXPORT: (accountId) => `/clients/accounts/${accountId}/export_qualifying_report`,
                SINGLE_EXPORT_PDF: (accountId, applicationId) => `/clients/accounts/${accountId}/applications/${applicationId}/export_pdf`,
                SINGLE_EXPORT_XLS: (accountId, applicationId) => `/clients/accounts/${accountId}/applications/${applicationId}/export_xls`,
                SINGLE_EXPORT_XLS_DONATIONS: (accountId, applicationId) => `/clients/accounts/${accountId}/applications/${applicationId}/export_donation_report `,
                BULK_SCHOLARSHIP_EXPORT_XLS:(accountId) => `/clients/accounts/${accountId}/export_donation_report `,
                SINGLE_EXPORT_XLS_DISBURSEMENTS: (accountId, applicationId) => `/clients/accounts/${accountId}/disbursements/${applicationId}/export_disbursement_report`,
                BULK_SCHOLARSHIP_EXPORT_XLS_DISBURSEMENTS:(accountId) => `/clients/accounts/${accountId}/export_disbursement_report`,
                SINGLE_SCHOLARSHIP_EXPORT_PDF: (accountId, scholarshipId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/export_pdf`,
                SINGLE_SCHOLARSHIP_EXPORT_XLS: (accountId, scholarshipId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/export_xls`,
                BULK_SCHOLARSHIP_EXPORT_XLS_FILTERED:(accountId, scholarshipId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/export_donation_report`,
                SCORE_REPORT_EXPORT: (accountId) => `/clients/accounts/${accountId}/export_score_report`,
                SINGLE_SCHOLARSHIP_SCORE_REPORT_EXPORT: (accountId, scholarshipId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/export_score_report`,
                SINGLE_SCORE_REPORT_EXPORT: (accountId, applicationId) => `/clients/accounts/${accountId}/applications/${applicationId}/export_score_report`,
                
                RECOMMENDER_STATUS_REPORT_EXPORT: (accountId) => `/clients/accounts/${accountId}/export_recommendation_report`,
                SINGLE_SCHOLARSHIP_RECOMMENDER_STATUS_REPORT_EXPORT: (accountId, scholarshipId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/export_recommendation_report`,
                SINGLE_RECOMMENDER_STATUS_REPORT_EXPORT: (accountId, applicationId) => `/clients/accounts/${accountId}/applications/${applicationId}/export_recommendation_report`,

                UPDATE: (accountId, scholarshipId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}`,
                EDIT: (accountId, scholarshipId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/edit`,
                GET_ALL_RBM: (accountId) => `/clients/accounts/${accountId}/review_board_members`,
                UPDATE_RBM: (accountId, scholarshipId, scorecardId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/scholarship_score_cards/${scorecardId}/review_board_members`,
                UNIQUE_URL: () => `/scholarships/check_unique_url`,
                TERMS_AND_CONDITION: () => `/scholarships/term_condition_acceptance`,
                UPDATE_APPLICATION_STATUS: (accountId, scholarshipId, applicationId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}/update_status`,
                UPDATE_AGGREMENT_RBM: (accountId, scholarshipId) => `/review_board_members/accounts/${accountId}/scholarships/${scholarshipId}/sign_term_condition`,                
                PROMOTE_AS_APPLICANT: () => `/user/promote_as_applicant`,
                UPDATE_AWARD_AMOUNT:(accountId, scholarshipId,applicationId)=>`/clients/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}/update_award_amount`,
                GET_SCAN_RESULT:(accountId, scholarshipId,applicationId)=>`/clients/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}/scan_results`,
                DELETE_APPLICATION: (accountId, scholarshipId, applicationId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}`
            },
            ADMIN: {
                FETCH_SCHOLARSHIP_TEMPLATE: (accountId) => `/clients/accounts/${accountId}/admin_scholarships`,
                FETCH_APPLICATION_TEMPLATE: (accountId) => `/clients/accounts/${accountId}/admin_application_steps`,
                FETCH_SCORECARD_TEMPLATE: (accountId) => `/clients/accounts/${accountId}/admin_scholarship_score_cards`,
                FETCH_RECOMMENDER_TEMPLATE: (accountId) => `/clients/accounts/${accountId}/admin_recommendation_custom_questions`,
            },
            APPLICATIONS: {
                GET_ALL: (accountId, scholarshipId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/applications`,
                CREATE: (accountId, scholarshipId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/application_steps`,
                CREATE_EXPORT_PDF: (accountId) => `/clients/accounts/${accountId}/export_pdf`,
                CREATE_EXPORT_XLS: (accountId) => `/clients/accounts/${accountId}/export_xls`,
                // CREATE_EXPORT_PDF: () => `/applications/export_pdf`,
                UPDATE: (accountId, scholarshipId, applicationId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/application_steps/${applicationId}`,
                GET_SINGLE: (accountId, scholarshipId, applicationId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}`,
                GET_APPLICATION_SINGLE: (accountId, scholarshipId, applicationId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}/application_documents`,
                UPDATE_APPLICATION_SINGLE: (accountId, scholarshipId, applicationId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}/application_documents`,
                BULK_UPDATE_STATUS : (accountId, scholarshipId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/update_application_status`,
                BULK_UPDATE_AWARD_AMOUNT:(accountId, scholarshipId)=>`/clients/accounts/${accountId}/scholarships/${scholarshipId}/update_award_amount`,
                CREATE_BULK_APPLICATION : (accountId, scholarshipId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/create_applications`,
                GET_CLIENT_NOTE: (accountId, scholarshipId, applicationId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}/client_notes`,
                UPDATE_CLIENT_NOTE: (accountId, scholarshipId, applicationId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}/client_notes`,
                DELETE_APPLICATION_RECOMMENDERS_CLIENT: (accountId, scholarshipId, applicationId, applicationStepId, recommenderId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}/${applicationStepId}/application_recommenders/${recommenderId}`,
                ADD_APPLICATION_RECOMMENDERS_CLIENT: (accountId, scholarshipId, applicationId, applicationStepId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}/${applicationStepId}/application_recommenders`,
                UPDATE_APPLICATION_RECOMMENDERS_CLIENT: (accountId, scholarshipId, applicationId, recommendationid) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}/application_recommenders/${recommendationid}`,
                DELETE_STEP: (accountId, scholarshipId, applicationId)=> `/clients/accounts/${accountId}/scholarships/${scholarshipId}/application_steps/${applicationId}`
            },
            RECOMMENDATIONS: {
                CREATE: (accountId, scholarshipId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/recommendation_custom_questions`,
                UPDATE: (accountId, scholarshipId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/recommendation_custom_questions`
            },
            SCORECARDS: {
                CREATE: (accountId, scholarshipId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/scholarship_score_cards`,
                UPDATE: (accountId, scholarshipId, scorecardId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/scholarship_score_cards/${scorecardId}`,
                DELETE: (accountId, scholarshipId, scorecardId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/scholarship_score_cards/${scorecardId}`
            },
            APPLICANTS: {
                GET_ALL: (accountId) => `/clients/accounts/${accountId}/applicants`,
            },
            REVIEW_BOARD_MEMBERS: {
                GET_ALL: (accountId, scholarshipId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/review_board_members`,
                ADD: (accountId, scholarshipId, reviewBoardMemberId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/review_board_members/${reviewBoardMemberId}/add`,
                NUDGE: (accountId, scholarshipId, reviewBoardMemberId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/review_board_members/${reviewBoardMemberId}/nudge`,
                REASSIGN: (accountId, scholarshipId, reviewBoardMemberId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/review_board_members/${reviewBoardMemberId}/reassign`,
                REMOVE: (accountId, scholarshipId, reviewBoardMemberId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/review_board_members/${reviewBoardMemberId}/remove`,
                INVITE: (accountId, scholarshipId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/review_board_members/invite`,
                SEARCH: (accountId, scholarshipId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/review_board_members/search`,
            },
            PEOPLE: {
                GET_ALL: (accountId) => `/clients/accounts/${accountId}/people`,
                RESET_PASSWORD_LINK: (accountId, contactId) => `/clients/accounts/${accountId}/contacts/${contactId}/send_reset_password_email`

            },
            REPORTS: {
                GET_ALL: (accountId) => `/clients/accounts/${accountId}/reports`,
                VIEW_DOWNLOAD_REPORT: (accountId, reportId) => `/clients/accounts/${accountId}/reports/${reportId}/report_view`,
                MULTIPLE_REPORT_DOWNLOAD: (accountId) => `/clients/accounts/${accountId}/reports/report_bulk_download`,
                GET_SHARED_REPORT: (accountId, reportId) => `/clients/accounts/${accountId}/reports/${reportId}/report_share`
            },
            DONATIONS:{
                GET_ALL: (accountId) => `/clients/accounts/${accountId}/donations`,
            },
            TASKS: {
                GET_ALL: (accountId) => `/clients/accounts/${accountId}/tasks`,
                CREATE: (accountId) => `/clients/accounts/${accountId}/tasks`,
                UPDATE: (accountId, task_id) => `/clients/accounts/${accountId}/tasks/${task_id}/update_status`,
                CONTACT: (accountId) => `/clients/accounts/${accountId}/donor_contacts`,
                GET_SINGLE: (accountId, task_id) => `/clients/accounts/${accountId}/tasks/${task_id}`,
                EDIT: (accountId, task_id) => `/clients/accounts/${accountId}/tasks/${task_id}`,
            },
            APPLICATION_SCORE_ROUNDS: {
                FETCH_ALL: (accountId, scholarshipId, applicationId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}/application_rounds`,
                FETCH_ONE: (accountId, scholarshipId, applicationId, roundId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}/application_rounds/${roundId}`
            },
            CHURNZERO_AUTH: {
                GET_TOKEN: (account_id) => `/clients/accounts/${account_id}/churn_zero_auth_token`
            },
            DISBURSEMENTS: {
                GET_ALL: (accountId) => `/clients/accounts/${accountId}/disbursements`,
                GET_PROGRESS:(accountId) => `/clients/accounts/${accountId}/disbursement_progress`,
                GET_DETAILS:(accountId,disbursementId)=>`/clients/accounts/${accountId}/disbursements/${disbursementId}/disbursement_details`,
                UPDATE:(accountId,disbursementId)=>`/clients/accounts/${accountId}/disbursements/${disbursementId}/update_disbursement`
            },
            ALASKA_AWARD: {
                GET_ALASKA_AWARD:  (accountId, scholarshipId, applicationId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}/alaska_award`,
                CREATE: (accountId, scholarshipId, applicationId) => `/clients/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}/alaska_award`,
            }
        },
        RECOMMENDOR: {
            RECOMMENDATION_QUESTIONS: {
                GET_ALL: (applicant_recommendation_id) => `/applicant_recommendations/${applicant_recommendation_id}/recommendation_questions`,
                UPDATE: (applicant_recommendation_id) => `/applicant_recommendations/${applicant_recommendation_id}/recommendation_questions`,
                DELETE_DOCUMENT: (recommendationid, recommendation_question_id) => `/applicant_recommendations/${recommendationid}/${recommendation_question_id}/document`,
                DELETE_DOCUMENT_NEW: (applicant_recommendation_id, recommendation_question_id, document_id) => `/applicant_recommendations/${applicant_recommendation_id}/${recommendation_question_id}/documents/${document_id}`
            }
        },
        REVIEW_BOARD_MEMBERS: {
            ACCOUNTS: {
                GET_ALL: () => '/review_board_members/accounts'
            },
            SCHOLARSHIPS: {
                GET_ALL: (accountId) => `/review_board_members/accounts/${accountId}/scholarships`,
                CREATE_EXPORT_PDF: (accountId) => `/review_board_members/accounts/${accountId}/export_pdf`,
                CREATE_EXPORT_XLS: (accountId) => `/review_board_members/accounts/${accountId}/export_xls`,

                SINGLE_EXPORT_PDF: (accountId, applicationId) => `/review_board_members/accounts/${accountId}/applications/${applicationId}/export_pdf`,
                SINGLE_EXPORT_XLS: (accountId, applicationId) => `/review_board_members/accounts/${accountId}/applications/${applicationId}/export_xls`,

                SINGLE_SCHOLARSHIP_EXPORT_PDF: (accountId, scholarshipId) => `/review_board_members/accounts/${accountId}/scholarships/${scholarshipId}/export_pdf`,
                SINGLE_SCHOLARSHIP_EXPORT_XLS: (accountId, scholarshipId) => `/review_board_members/accounts/${accountId}/scholarships/${scholarshipId}/export_xls`,

            },
            APPLICATIONS: {
                GET_ALL: (accountId, scholarshipId) => `/review_board_members/accounts/${accountId}/scholarships/${scholarshipId}/applications`,
                GET_SINGLE: (accountId, scholarshipId, applicationId) => `/review_board_members/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}`,
                EXPORT_PDF: (applicationId) => `/applications/${applicationId}/export_pdf`,
                GET_CLIENT_NOTE: (accountId, scholarshipId, applicationId) => `/review_board_members/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}/client_notes`,
            },
            APPLICATION_SCORE: {
                FETCH_ALL: (accountId, scholarshipId, applicationId) => `/review_board_members/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}/application_scores`,
                SUBMIT: (accountId, scholarshipId, applicationId) => `/review_board_members/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}/application_scores`
            },
            TEAM_MEMBERS: {
                FETCH_ALL: (accountId, scholarshipId, scholarshipRoundId) => `/review_board_members/accounts/${accountId}/scholarships/${scholarshipId}/scholarship_rounds/${scholarshipRoundId}/team_members`
            },
            TEAM_NOTE: {
                CREATE: (accountId, scholarshipId, applicationId) => `/review_board_members/accounts/${accountId}/scholarships/${scholarshipId}/applications/${applicationId}/team_notes`
            }
        },
        APPLICANT_RECOMMENDATIONS: {
            GET_ALL: () => '/applicant_recommendations'
        },
        APPLICANT: {
            ACCOUNTS: {
                GET_ALL: () => '/memberships',
            },
            PROFILE: {
                FETCH: () => '/user/profile',
                UPDATE: () => '/user/profile',
            },
            SCHOLARSHIPS: {
                GET_ALL: () => '/scholarships',
                GET: (id) => `/scholarships/${id}`,
                APPLY_SCHOLARSHIP: (scholarshipId, applicationStepId) => `/scholarships/${scholarshipId}/apply/${applicationStepId}`,
                APPLY_SCHOLARSHIP_WITHOUT_LOGIN: (scholarshipId, applicationStepId) => `/scholarships/${scholarshipId}/apply/${applicationStepId}`,
                SUBMIT_SCHOLARSHIP: (scholarshipId, applicationStepId) => `/scholarships/${scholarshipId}/apply/${applicationStepId}`,
                DELETE_REFERENCE: (scholarshipId, applicationStepId, applicationId, referenceId) => `/scholarships/${scholarshipId}/${applicationStepId}/${applicationId}/references/${referenceId}`,
                DELETE_ITERATION: (scholarshipId, applicationStepId, applicationId, questionSetNumber) => `/scholarships/${scholarshipId}/${applicationStepId}/${applicationId}/iterations/${questionSetNumber}`,
                RESUME_SCHOLARSHIP: (scholarshipId, applicationId) => `/scholarships/${scholarshipId}/${applicationId}/resume`,
                SUBMIT_APPLICATION: (scholarshipId, applicationId) => `/scholarships/${scholarshipId}/${applicationId}/submit`,
                SUBMIT_APPLICATION_RATING: (scholarshipId, applicationId) => `/scholarships/${scholarshipId}/${applicationId}/feedback`,
                DELETE_DOCUMENT: (scholarshipId, applicationStepId, applicationId, documentId) => `/scholarships/${scholarshipId}/${applicationStepId}/${applicationId}/documents/${documentId}`,
                ESIGN_URL: (scholarshipId, applicationId) => `/scholarships/${scholarshipId}/${applicationId}/docusign_url`,


                PRINT_PDF: (applicationId) => `/applications/${applicationId}/export_pdf`,
                ACCESS_CODE_CHECK :(scholarshipId)=> `/scholarships/${scholarshipId}/check_access_code`,
                PARENT_CONSENT_FOR_SCHOLARSHIP: (scholarshipId) => `/scholarships/${scholarshipId}/ask_parent_consent`,
                PARENT_CONSENT_FORM: (scholarshipId) => `/scholarships/${scholarshipId}/create_parent_consent`,
            },
            SAVEDSCHOLARSHIPS: {
                GET_ALL: () => '/scholarships/bookmarks',
                UPDATE: (id) => `/scholarships/${id}/bookmark`,
                REMOVE: (id) => `/scholarships/${id}/bookmark`
            },
            GRADEBOOK: {
                GET_ALL: () => '/gradebooks',
                CREATE: () => '/gradebooks',
                GET_SINGLE: (gradebookId) => `/gradebooks/${gradebookId}`,
                UPDATE: (gradebookId) => `/gradebooks/${gradebookId}`,
                REMOVE_DOCUMENT: (gradebookId, documentId) => `/gradebooks/${gradebookId}/document/${documentId}`,
                REMOVE: (gradebookId) => `/gradebooks/${gradebookId}`
            },
            TASKS: {
                GET_ALL: () => '/applicant_tasks',
                UPDATE: (id) => `/applicant_tasks/${id}`,
                GET_SINGLE: (id) => `/applicant_tasks/${id}`,
                CREATE: () => '/applicant_tasks'
            },
            DISBURSEMENTS: {
                GET_ALL: () => '/disbursements'
            },
            RESOURCES: {
                GET_ALL: () => '/applicant_documents',
                CREATE: () => '/applicant_documents',
                UPDATE: (id) => `/applicant_documents/${id}`,
                REMOVE: (id) => `/applicant_documents/${id}`
            },
            APPLICATIONS: {
                GET_ALL: () => '/applications'
            },
            DONOR: {
                FETCH_ALL: () => `/donations`
            },
            DONARS_INFO: {
                FETCH_ALL: () => `/donor_informations`
            }
        },
        UNIVERSITYSTAFF: {
            APPLICATIONS: {
                GET_ALL: () => `/staff_role_applications`,
                SINGLE_APPLICATION: (applicationId) => `/staff_role_applications/${applicationId}`,
                UPDATE_ALL_APPLICATION: () => `/staff_role_applications/update_all`,
            }
        },
        ADMIN: {
            LOGIN: () => "/salesforce-auth-sign-in",
            USER_DETAIL: () => "/admin/user_detail",
            USER_LIST: () => "/admin/users",
            DUPLICATE_CONTACTS_LIST: () => "/admin/duplicate_contacts",
            REMOVE_DUPLICATE_CONTACT: () => "/admin/duplicate_contacts",
            USER_ACTIVITIES: () => "/admin/user_activities",
            USER_EMAIL_HISTORY: () => "/admin/user_email_history",
            LOGIN_HISTORY: () => "/admin/login_history",
            APPLICATION_RECOMMENDERS: () => "/admin/application_recommenders",
            APPLICATION_DOCUMENT: () => "/admin/application_documents",
            ADD_DOCUMENT: (applicationId) => `/admin/application_documents/${applicationId}`,
            UPDATE_APPLICATION_RECOMMENDERS: (recommenderHerokuId) => `/admin/application_recommenders/${recommenderHerokuId}`,
            JOB_ACTIVITY: () => "/admin/delayed_job_queues",
            BULKPASSWORDUPDATECSV: () => "/admin/bulk_password_update",
            FAILED_USER_RECORDS: (delayedJobQueueId) => `/admin/delayed_job_queues/${delayedJobQueueId}/delayed_job_failed_records`,
            ACTIVE_USERS: () => "/admin/active_users",
            PROMOTE_USERS: () => "/admin/promote_user",
            BULK_PROMOTE_USERS: () => "/admin/bulk_promote_user",
            CHART_DATA: () => "/admin/chart_data",
            FRONTENDTOSALESFORCESYNC: () => "/admin/heroku_connect_trigger_logs",
            FRONTENDAPPLICATIONSYNCTOELEMENTS: () => "/admin/modified_application_rows",
            FRONTENDCONTACTSYNCTOELEMENTS: () => "/admin/modified_contact_rows",
            ROWSINFRONTENDDATABASE : ()=> "/admin/heroku_connect_database_rows",
            HEROKUCONNECTERRORS : ()=> "/admin/heroku_connect_errored_rows",
            HEROKUERRORDETAILS : (trigger_log__id)=> `/admin/heroku_connect_errored_rows/${trigger_log__id}/show`,
            RETRYSYNCHEROKUCONNECT : (trigger_log__id)=> `/admin/heroku_connect_errored_rows/${trigger_log__id}/retry`,
            SSODETAILSADMIN : (str)=> `/admin/accounts/${str}/sso_detail`,
            SSOACCOUNTLIST : ()=> `/admin/sso_details`,
            COPYLEAKSSCAN : (scholarship_Id) => `/admin/scholarships/${scholarship_Id}/start_scan`,
            PORTFOLIOPROGRAMCHECKEREXPORT : (scholarship_Id) => `/admin/scholarships/${scholarship_Id}/scholarship_filter_export`,
            DISBURSEMENTS_ACH_INFO_EXPORT : ()=>  `/admin/disbursement_ach_info/export`
        },
        UNSUBSCRIBE : (receiver_email)=> `/notification_setting/${receiver_email}/unsubscribe`
    },
    // This is used to determine the type of data for table columns.
    DATA_TYPES: {
        TEXT: 'Text'
    },
    NAV_MENU_MAP: {
        DONOR: [
            { apiLabel: 'Overview', url: MAIN_CONFIG.URLS.DONOR.HOME },
            { apiLabel: 'Programs', url: MAIN_CONFIG.URLS.DONOR.SCHOLARSHIPS },
            { apiLabel: "Applications", url: MAIN_CONFIG.URLS.DONOR.APPLICANTS },
            { apiLabel: 'People', url: MAIN_CONFIG.URLS.DONOR.PEOPLE },
            { apiLabel: 'Admin', url: MAIN_CONFIG.URLS.DONOR.ADMIN },
            { apiLabel: 'Disbursements', url: MAIN_CONFIG.URLS.DONOR.DISBURSEMENTS },
            { apiLabel: 'Reports', url: MAIN_CONFIG.URLS.DONOR.REPORTS },
            { apiLabel: 'Connect', url: MAIN_CONFIG.URLS.DONOR.COMMUNICATIONS },
            { apiLabel: 'Donations', url: MAIN_CONFIG.URLS.DONOR.DONATIONS }
        ],
        REVIEWER: [
            { apiLabel: 'Dashboard', url: MAIN_CONFIG.URLS.REVIEWER.DASHBOARD }
        ]
    }
};
