import React from 'react';
import { API_CONFIG } from '../../config/api';
import fetch from 'isomorphic-unfetch';
import { MAIN_CONFIG, allowedAttributes, allowedIframeHostnames, tagsWithUrl, urlAttr } from '../../config/main';
import cookies from 'next-cookies'
import sanitizeHtml from 'sanitize-html';
import he from 'he';
import { isValidURL } from '../Utility/HelperFunctions';
import { BlobServiceClient } from '@azure/storage-blob';
export const apiURL = `${API_CONFIG.BASE_API_URL}${API_CONFIG.API_VERSION_PATH}`;
const { USER_CONTEXT } = MAIN_CONFIG;
const { v4: uuidv4 } = require('uuid');

const AZURE_BASE_URL= process.env.NEXT_PUBLIC_BLOB_BASE_URL
const CONTAINER_NAME = process.env.NEXT_PUBLIC_BLOB_STORAGE_CONTAINER
const SAS_TOKEN = process.env.NEXT_PUBLIC_BLOB_SAS_TOKEN
/**
 * This class is a wrapper around the Kaleidoscope Back-end API.
 */
class KaleidoscopeAPI extends React.Component {

    /**
     * Return the authentication response.
     * @param {string} email
     * @param {string} password
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    authenticateSignUp = async (email, password) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.AUTH.SIGN_IN(),
            {
                method: 'POST',
                body: { email, password }
            }
        );
    }

    /**
     * Return the authentication response.
     * @param {string} email
     * @param {string} password
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    authenticate = async (email, password, setApplicant = false) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.AUTH.SIGN_IN(),
            {
                method: 'POST',
                body: { email, password, set_applicant: setApplicant }
            }
        );
    }

    /**
     * Return the authentication response.
     * @param {string} email
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    checkExistingUser = async (email) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.AUTH.CHECKUSER(),
            {
                method: 'POST',
                body: { email: email }
            }
        );
    }

    /**
     * Return the authentication response.
     * @param {string} sso code
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    authenticateViaSso = async ({ code }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.AUTH.SSO.FETCH_TOKEN(),
            {
                method: 'POST',
                body: { secure_login_id: code }
            }
        );
    }

    /**
     * sign out user from server.
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    signOut = async (token) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.AUTH.SIGN_OUT(),
            {
                method: 'DELETE',
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Return the SAMP auth URL as response.
     * @param {string} accountId
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchSamlSSOUrl = async ({ accountId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.AUTH.SAML.FETCH_URL(accountId),
            {
                method: 'GET'
            }
        );
    }

    /**
     * Return the SSO token response.
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchSsoCode = async ({ token }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.AUTH.SSO.GET_CODE(),
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: {}
            }
        );
    }

    /**
     * Return the authentication response.
     * @param {string} email
     * @param {string} firstName
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    parentalConsentRequest = async ({ applicant_name, guardian_email, scholarshipId }) => {
        var url = API_CONFIG.END_POINTS.AUTH.PARENT_CONSENT_FOR_SCHOLARSHIP()
        if (scholarshipId) {
            url = API_CONFIG.END_POINTS.APPLICANT.SCHOLARSHIPS.PARENT_CONSENT_FOR_SCHOLARSHIP(scholarshipId)
        }
        return await this.fetchData(
            url,
            {
                method: 'POST',
                body: { applicant_name, guardian_email }
            }
        );
    }

    /**
     * Return the authentication response.
     * @param {string} email
     * @param {string} firstName
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    guardianConsentRequestForm = async ({ params, scholarshipId }) => {
        var url = API_CONFIG.END_POINTS.AUTH.PARENT_CONSENT_FORM()
        if (scholarshipId) {
            url = API_CONFIG.END_POINTS.APPLICANT.SCHOLARSHIPS.PARENT_CONSENT_FORM(scholarshipId)
        }
        return await this.fetchData(
            url,
            {
                method: 'POST',
                body: { contact: params }
            }
        );
    }


    /**
     * Return the authentication response.
     * @param {string} email
     * @param {string} password
     * @param {string} firstName
     * @param {string} lastName
     * @param {string} birthday
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    authenticateSignUpOnboarding = async (user) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.AUTH.SIGN_UP(),
            {
                method: 'POST',
                body: { user }
            }
        );
    }
    authenticateSignUpInstitutions = async (searchTerm, auto_suggest_data, suggestion_for_gradebook, token) => {
        var url = API_CONFIG.END_POINTS.AUTH.SIGN_UP_INSTITUTION()
        if (searchTerm) {
            url = API_CONFIG.END_POINTS.AUTH.SIGN_UP_INSTITUTION_SEARCH(searchTerm)
        }

        if (searchTerm, auto_suggest_data, suggestion_for_gradebook) {
            url = API_CONFIG.END_POINTS.AUTH.SIGN_UP_INSTITUTION_NAME_WITH_PARAMS(searchTerm, auto_suggest_data, suggestion_for_gradebook, token)
        }
        return await this.fetchData(
            url,
            {
                method: 'GET',
                headers: {
                    'Authorization': token
                },
            }
        );
    }

    /**
     * Return the authentication response.
     * @param {string} school
     * @param {string} grade
     * @param {string} gpaVal
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    authenticateSignUpSecondForm = async (token, school, grade, gpaVal) => {
        var user = {
            ...school,
            grade_level: grade.value,
            gpa: gpaVal
        }
        return await this.fetchData(
            API_CONFIG.END_POINTS.AUTH.SIGN_UP(),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT',
                body: { user }
            }
        );
    }

    /**
     * Return the authentication response.
     * @param {string} gender
     * @param {string} ethnicity
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    authenticateSignUpThirdForm = async (token, gender, ethnicity) => {
        var user = {
            gender: gender,
            race_ethinicity: ethnicity
        }
        return await this.fetchData(
            API_CONFIG.END_POINTS.AUTH.SIGN_UP(),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT',
                body: { user }
            }
        );
    }
    /**
         * Return the aggrement response.
        * @param {string} token
        * @returns {Promise<{errorMessage: *, error: boolean}|*>}
        */
    updateAgreementRBM = async ({ term_condition, token, accountId, scholarshipId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.UPDATE_AGGREMENT_RBM(accountId, scholarshipId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: { term_condition }
            }
        );
    }



    /**
     * Return the authentication response.
     * @param {string} email
     * @param {string} password
     * @param {string} firstName
     * @param {string} lastName
     * @param {string} organizationName
     * @param {string} birthday
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    sponsorSignUpOnbording = async (firstName, lastName, birthday, email, password, organizationName, websiteURL) => {
        var user = {
            first_name: firstName,
            last_name: lastName,
            dob: birthday,
            email: email,
            password: password
        }
        var account = {
            name: organizationName,
            website: websiteURL
        }

        return await this.fetchData(
            API_CONFIG.END_POINTS.AUTH.SPONSOR_SIGN_UP(),
            {
                method: 'POST',
                body: { user, account }
            }
        );
    }
    /**
     * Return the OTP response.
     * @param {string} email
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    createOTP = async (email) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.AUTH.CREATE_OTP(),
            {
                method: 'POST',
                body: { email }
            }
        );
    }

    /**
     * Return the OTP authorization response.
     * @param {string} email
     * @param {string} otp
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    authorizeOTP = async (email, otp) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.AUTH.AUTHORIZE_OTP(),
            {
                method: 'POST',
                body: { email, otp }
            }
        );
    }

    /**
     * Return the response.
     * @param {string} email
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    sendPasswordLink = async ({ token, email }) => {

        return await this.fetchData(
            API_CONFIG.END_POINTS.AUTH.SENDPASSWORDLINK(),
            {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: email
            }
        );
    }

    /**
    * Return the response.
    * @param {string} email
    * @returns {Promise<{errorMessage: *, error: boolean}|*>}
    */
    googleLogin = async (payload) => {

        return await this.fetchData(
            API_CONFIG.END_POINTS.AUTH.GOOGLELOGIN(),
            {
                method: 'POST',
                body: payload
            }
        );
    }

    /**
     * Return the response.
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    resetPasswordLinkCheck = async ({ token }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.AUTH.CHECKRESETPASSWORDLINK(),
            {
                headers: {
                    'Authorization': token
                },
                method: 'GET'
            }
        );
    }

    /**
     * Return the response.
     * @param {string} email
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    resetPassword = async ({ token, password }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.AUTH.RESETPASSWORD(),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT',
                body: {
                    password
                }
            }
        );
    }

    /**
     * Create a scholarship.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} name
     * @param {string} fundAmount
     * @param {string} openDate - format: Nov 3, 2020
     * @param {string} deadlineDate - format: Jan 6, 2021
     * @param {string} description
     * @param {number} numRecipients
     * @param {string} categories - Semicolon delimited
     * @param {string} citizenship
     * @param {string} minGPA
     * @param {string} gradeLevel
     * @param {number} applicationLimit
     * @param {boolean} isBlindReview
     * @param {string} awardDeadlineDate - format: Jan 13, 2021
     * @param {string} preUrl
     * @param {string} url
     *
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    createScholarship = async ({
        token,
        accountId,
        name,
        fundAmount,
        openDate,
        deadlineDate,
        description,
        introduction,
        numRecipients,
        categories,
        citizenship,
        stateEligibility,
        minGPA,
        gradeLevel,
        applicationLimit,
        isBlindReview,
        awardDeadlineDate,
        awardStartDate,
        preUrl,
        url,
        recommendationDeadlineDate,
        qualifications,
        qualificationText,
        recommenderInstructions,
        scholarshipLogoUrl,
        scholarshipHeaderLogoUrl,
        status,
        showAmount,
        showCurrency,
        application_steps,
        primaryColor,
        secondaryColor,
        accessCodeToEditAfterDeadline,
        allowMultipleApplications,
        allowEditAfterSubmission,
        allowEditAfterRecSubmission,
        allowApplicantUploadsAfterDeadline,
        timeZone,
        country,
    }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.CREATE(accountId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: {
                    scholarship: {
                        name,
                        scholarship_fund_amount__c: fundAmount,
                        open_date_converted__c: openDate,
                        date_timezone__c: deadlineDate,
                        description__c: description,
                        introduction__c: introduction,
                        number_of_recipients__c: numRecipients,
                        scholarship_category__c: categories,
                        citizenship__c: citizenship,
                        state_eligibility__c: stateEligibility,
                        minimum_gpa__c: minGPA,
                        grade_level__c: gradeLevel,
                        application_limit__c: applicationLimit,
                        blind_review__c: isBlindReview,
                        award_announcement__c: awardDeadlineDate,
                        award_start_date__c: awardStartDate,
                        pre_url_name__c: preUrl,
                        url_name__c: url,
                        recommendation_due_date__c: recommendationDeadlineDate,
                        qualifications__c: qualifications,
                        qualification_text__c: qualificationText,
                        status__c: status,
                        scholarshiplogourl__c: scholarshipLogoUrl,
                        scholarship_header_logo_url__c: scholarshipHeaderLogoUrl,
                        recommendation_instructions__c: recommenderInstructions,
                        show_fund_amount__c: showAmount,
                        show_currency_symbol__c: showCurrency,
                        primary_branding_color__c: primaryColor,
                        secondary_branding_color__c: secondaryColor,
                        access_code_to_edit_after_deadline__c:accessCodeToEditAfterDeadline,
                        allow_multiple_applications__c: allowMultipleApplications,
                        allow_edit_after_application_submission__c: allowEditAfterSubmission,
                        allow_edit_after_rec_submission__c: allowEditAfterRecSubmission,
                        allow_applicant_uploads_after_deadline__c: allowApplicantUploadsAfterDeadline,
                        country__c: country,
                        timezone__c: timeZone
                    },
                    application_steps: application_steps
                }
            }
        );
    }

    /**
     * Update a scholarship.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId - Scholarship SFID
     * @param {string} name
     * @param {string} fundAmount
     * @param {string} openDate - format: Nov 3, 2020
     * @param {string} deadlineDate - format: Jan 6, 2021
     * @param {string} description
     * @param {number} applicationId
     * @param {number} numRecipients
     * @param {string} categories - Semicolon delimited
     * @param {string} citizenship
     * @param {string} minGPA
     * @param {string} gradeLevel
     * @param {number} applicationLimit
     * @param {boolean} isBlindReview
     * @param {string} awardDeadlineDate - format: Jan 13, 2021
     * @param {number} applicationId
     * @param {string} preUrl
     * @param {string} url
     *
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    updateScholarship = async ({
        token,
        accountId,
        scholarshipId,
        name,
        fundAmount,
        openDate,
        deadlineDate,
        description,
        introduction,
        applicationId,
        numRecipients,
        categories,
        citizenship,
        stateEligibility,
        minGPA,
        gradeLevel,
        applicationLimit,
        isBlindReview,
        awardDeadlineDate,
        awardStartDate,
        preUrl,
        url,
        recommendationDeadlineDate,
        qualifications,
        qualificationText,
        recommenderInstructions,
        scholarshipLogoUrl,
        scholarshipHeaderLogoUrl,
        status,
        showAmount,
        showCurrency,
        application_steps,
        primaryColor,
        secondaryColor,
        accessCodeToEditAfterDeadline,
        allowMultipleApplications,
        allowEditAfterSubmission,
        allowEditAfterRecSubmission,
        allowApplicantUploadsAfterDeadline,
        timeZone,
        country,
    }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.UPDATE(accountId, scholarshipId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT',
                body: {
                    scholarship: {
                        name,
                        scholarship_fund_amount__c: fundAmount,
                        open_date_converted__c: openDate,
                        date_timezone__c: deadlineDate,
                        description__c: description,
                        introduction__c: introduction,
                        number_of_recipients__c: numRecipients,
                        scholarship_category__c: categories,
                        citizenship__c: citizenship,
                        state_eligibility__c: stateEligibility,
                        minimum_gpa__c: minGPA,
                        grade_level__c: gradeLevel,
                        application_limit__c: applicationLimit,
                        blind_review__c: isBlindReview,
                        award_announcement__c: awardDeadlineDate,
                        award_start_date__c: awardStartDate,
                        pre_url_name__c: preUrl,
                        url_name__c: url,
                        recommendation_due_date__c: recommendationDeadlineDate,
                        qualifications__c: qualifications,
                        qualification_text__c: qualificationText,
                        status__c: status,
                        scholarshiplogourl__c: scholarshipLogoUrl,
                        scholarship_header_logo_url__c: scholarshipHeaderLogoUrl,
                        recommendation_instructions__c: recommenderInstructions,
                        show_fund_amount__c: showAmount,
                        show_currency_symbol__c: showCurrency,
                        primary_branding_color__c: primaryColor,
                        secondary_branding_color__c: secondaryColor,
                        access_code_to_edit_after_deadline__c:accessCodeToEditAfterDeadline,
                        allow_multiple_applications__c: allowMultipleApplications,
                        allow_edit_after_application_submission__c: allowEditAfterSubmission,
                        allow_edit_after_rec_submission__c: allowEditAfterRecSubmission,
                        allow_applicant_uploads_after_deadline__c: allowApplicantUploadsAfterDeadline,
                        country__c: country,
                        timezone__c: timeZone

                    },
                    application_steps: application_steps,
                    scholarship_application_id: applicationId
                }
            }
        );
    }

    /**
     * Delete document from cloud.
     * @param {string} id
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    deleteUploadedLogoScholarship = async ({ token, recommendationid, id }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.RECOMMENDOR.RECOMMENDATION_QUESTIONS.DELETE_DOCUMENT(recommendationid, id),
            {
                method: 'DELETE',
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Fetches account details for marketplace
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchAccountData = async ({ marketPlace }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ACCOUNT.FETCH_DETAILS(marketPlace),
            {
                method: 'GET'
            }
        );
    }
    updateAwardAmount = async ({ token, accountId, scholarshipId, applicationId, application }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.UPDATE_AWARD_AMOUNT(accountId, scholarshipId, applicationId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT',
                body: { application: application }
            }
        );



        return { awardAmount }
    }

    // Bulk award amount update form client portal 
    BulkAwardAmountUpdate = async ({ token, is_all_selected, selected_application_ids, removed_application_ids, accountId, scholarshipId, award_amount, award_amount_notes, search, status}) => {
        const { search_keys, search_value } = search
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.APPLICATIONS.BULK_UPDATE_AWARD_AMOUNT(accountId, scholarshipId),
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: {
                    removed_application_ids,
                    selected_application_ids,
                    is_all_selected,
                    primary_tab: 'Application',
                    application_status: status,
                    application: { award_amount__c: award_amount, award_amount_notes__c: award_amount_notes },
                    search_keys,
                    search_value
                }
            }
        );
    }

    fetchScanResults = async ({ token, accountId, scholarshipId, applicationId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.GET_SCAN_RESULT(accountId, scholarshipId, applicationId),
            {
                method: 'GET',
                headers: {
                    'Authorization': token
                }
            }
        );
    }
    /**
     * Fetches scholarship builder tooltips, descrption data
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    scholarshipBuilderkeys = async ({ token }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.GET_BUILDER_TOOLTIPS(),
            {
                method: 'GET',
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Fetches scholarship builder configuration for scorecard template
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    scholarshipBuilderScorecardTemplates = async ({ token, accountId, options = {} }) => {
        const { page, sort } = options;
        const sortParam = sort ? { order_with: sort } : {};

        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.ADMIN.FETCH_SCORECARD_TEMPLATE(accountId),
            {
                method: 'GET',
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    ...sortParam
                }
            }
        );
    }

    /**
     * Fetches scholarship builder configuration for recommender template
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    scholarshipBuilderRecommenderTemplates = async ({ token, accountId, options = {} }) => {
        const { page, sort } = options;
        const sortParam = sort ? { order_with: sort } : {};

        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.ADMIN.FETCH_RECOMMENDER_TEMPLATE(accountId),
            {
                method: 'GET',
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    ...sortParam
                }
            }
        );
    }

    /**
     * Fetches scholarship builder configuration for application template
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    scholarshipBuilderApplicationTemplates = async ({ token, accountId, options = {} }) => {
        const { page, sort } = options;
        const sortParam = sort ? { order_with: sort } : {};

        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.ADMIN.FETCH_APPLICATION_TEMPLATE(accountId),
            {
                method: 'GET',
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    ...sortParam
                }
            }
        );
    }

    /**
     * Fetches scholarship builder configuration for scholarship template
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    scholarshipBuilderScholarshipTemplates = async ({ token, accountId, options = {} }) => {
        const { page, sort } = options;
        const sortParam = sort ? { order_with: sort } : {};

        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.ADMIN.FETCH_SCHOLARSHIP_TEMPLATE(accountId),
            {
                method: 'GET',
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    ...sortParam
                }
            }
        );
    }

    /**
     * Get Dropbox Token from dropbox api.
     * @param {string} url
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    getDropBoxToken = async ({ url, options = {} }) => {
        let authString = `${process.env.NEXT_PUBLIC_DROPBOX_APP_KEY}:${process.env.NEXT_PUBLIC_DROPBOX_APP_SECRET}`
        let headers = new Headers();
        return await this.fetchData(
            url,
            {
                method: 'POST',
                headers: {
                    'Authorization': 'Basic ' + btoa(authString)
                },
                body: JSON.stringify({
                    'client_id': process.env.NEXT_PUBLIC_DROPBOX_APP_KEY,
                    'client_secret': process.env.NEXT_PUBLIC_DROPBOX_APP_SECRET
                }),
                dropbox: true
            }
        );
    }
    /**
     * Create an Application Section/Step.
     * @param {string} token
     * @param {string} accountId
     * @param {string} scholarshipId
     * @param {object} formattedStepData
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    createApplicationStep = async ({ token, accountId, scholarshipId, formattedStepData }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.APPLICATIONS.CREATE(accountId, scholarshipId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: {
                    ...formattedStepData
                }
            }
        );
    }

    // api call for export file in csv or pdf in scholarship
    /**
     * Create an Application Section/Step.
     * @param {string} token
     * @param {string} selected_scholarship_ids
     * @param {string} removed_scholarship_ids
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */

    addScholarshipExport = async ({ token, accountId, is_all_selected, selected_scholarship_ids, removed_scholarship_ids, type, search_keys, search_value, scholarship_status, userContext }) => {

        let endPoint;
        switch (userContext) {
            case USER_CONTEXT.DONOR:
            case USER_CONTEXT.SUPERDONOR:
                endPoint = (type === "csv") ?
                    API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.CREATE_EXPORT_XLS(accountId)
                    :
                    (type === "pdf") ?
                        API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.CREATE_EXPORT_PDF(accountId)
                        :
                        (type === "score") ?
                            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.SCORE_REPORT_EXPORT(accountId)
                            :
                            (type === "qualifying_scholarship") ? 
                                API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.QUALIFYING_SCHOLARSHIP_EXPORT(accountId)
                                :
                                    API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.RECOMMENDER_STATUS_REPORT_EXPORT(accountId)
                break;
            case USER_CONTEXT.REVIEWER:
                endPoint = (type === "csv") ?
                    API_CONFIG.END_POINTS.REVIEW_BOARD_MEMBERS.SCHOLARSHIPS.CREATE_EXPORT_XLS(accountId)
                    :
                    API_CONFIG.END_POINTS.REVIEW_BOARD_MEMBERS.SCHOLARSHIPS.CREATE_EXPORT_PDF(accountId)
                break;
            default:
                return {
                    error: 'ACCESS DENIED',
                    errorMessage: 'The current user context does not have access to the requested resource.'
                }
        }
        return await this.fetchData(
            endPoint,
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: {
                    removed_scholarship_ids,
                    selected_scholarship_ids,
                    is_all_selected,
                    search_keys,
                    search_value,
                    primary_tab: 'Scholarship',
                    scholarship_status
                }
            }
        );
    }

    // add export csv or pdf for applicants

    addApplicantsExport = async ({ token, accountId, is_all_selected, type, search_keys, search_value, selected_application_ids, removed_application_ids, applicant_filters }) => {

        const url = (type === "csv") ?
            API_CONFIG.END_POINTS.DONORS.APPLICATIONS.CREATE_EXPORT_XLS(accountId)
            :
            API_CONFIG.END_POINTS.DONORS.APPLICATIONS.CREATE_EXPORT_PDF(accountId)
        return await this.fetchData(
            url,
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: {
                    selected_application_ids,
                    removed_application_ids,
                    is_all_selected,
                    search_keys,
                    search_value,
                    primary_tab: 'Applicant',
                    applicant_filters
                }
            }
        );
    }

    // api call for export file in csv or pdf in scholarship
    /**
     * Create an Application Section/Step.
     * @param {string} token
     * @param {string} selected_scholarship_ids
     * @param {string} removed_scholarship_ids
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */

    scholarshipApplicationExport = async ({ token, accountId, scholarshipId, is_all_selected, selected_application_ids, removed_application_ids, type, search_keys, search_value, application_status, userContext }) => {
        let endPoint;
        switch (userContext) {
            case USER_CONTEXT.DONOR:
            case USER_CONTEXT.SUPERDONOR:
                endPoint = (type === "csv") ?
                    API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.SINGLE_SCHOLARSHIP_EXPORT_XLS(accountId, scholarshipId)
                    :
                    (type === "pdf") ?
                        API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.SINGLE_SCHOLARSHIP_EXPORT_PDF(accountId, scholarshipId)
                        :
                        (type === "score") ?
                            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.SINGLE_SCHOLARSHIP_SCORE_REPORT_EXPORT(accountId, scholarshipId)
                            :
                            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.SINGLE_SCHOLARSHIP_RECOMMENDER_STATUS_REPORT_EXPORT(accountId, scholarshipId)
                break;
            case USER_CONTEXT.REVIEWER:
                endPoint = (type === "csv") ?
                    API_CONFIG.END_POINTS.REVIEW_BOARD_MEMBERS.SCHOLARSHIPS.SINGLE_SCHOLARSHIP_EXPORT_XLS(accountId, scholarshipId)
                    :
                    API_CONFIG.END_POINTS.REVIEW_BOARD_MEMBERS.SCHOLARSHIPS.SINGLE_SCHOLARSHIP_EXPORT_PDF(accountId, scholarshipId)
                break;
            default:
                return {
                    error: 'ACCESS DENIED',
                    errorMessage: 'The current user context does not have access to the requested resource.'
                }
        }

        return await this.fetchData(
            endPoint,
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: {
                    removed_application_ids,
                    selected_application_ids,
                    is_all_selected,
                    search_keys,
                    search_value,
                    primary_tab: 'Application',
                    application_status
                }
            }
        );
    }

    disbursementsApplicationExport = async ({ token, accountId, scholarshipId, is_all_selected, selected_disbursement_ids, removed_disbursement_ids, disbursement_status, type, search_keys, search_value, application_status, applicant_filters, selected_scholarship_ids
    }) => {
        let endPoint = API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.BULK_SCHOLARSHIP_EXPORT_XLS_DISBURSEMENTS(accountId)

        return await this.fetchData(
            endPoint,
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: {
                    removed_disbursement_ids,
                    selected_disbursement_ids,
                    is_all_selected,
                    search_keys,
                    search_value,
                    primary_tab: 'Disbursement',
                    application_status,
                    selected_scholarship_ids,
                    disbursement_status
                },
            }
        );
    }

    donationsApplicationExport = async ({ token, accountId, scholarshipId, is_all_selected, selected_application_ids, removed_application_ids, type, search_keys, search_value, application_status, applicant_filters, selected_scholarship_ids
    }) => {
        let endPoint = API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.BULK_SCHOLARSHIP_EXPORT_XLS(accountId)

        return await this.fetchData(
            endPoint,
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: {
                    removed_application_ids,
                    selected_application_ids,
                    is_all_selected,
                    search_keys,
                    search_value,
                    primary_tab: 'Donation',
                    application_status,
                    selected_scholarship_ids
                },
            }
        );
    }
    // Bulk Update of Application status inside the scholarships 

    bulkApplicationStatusUpdate = async ({ token,
        is_all_selected,
        selected_application_ids,
        removed_application_ids,
        accountId,
        scholarshipId,
        scholarship_status, search, status }) => {

        const { search_keys, search_value } = search

        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.APPLICATIONS.BULK_UPDATE_STATUS(accountId, scholarshipId),
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: {
                    removed_application_ids,
                    selected_application_ids,
                    is_all_selected,
                    primary_tab: 'Application',
                    application: { status__c: scholarship_status.value },
                    application_status: status,
                    search_keys,
                    search_value
                }
            }
        );
    }

  // Bulk Application create inside the scholarships form client portal 
  createBulkApplication = async ({ 
    token,
    is_all_selected,
    selected_application_ids,
    removed_application_ids,
    accountId,
    scholarshipId,
    search,
    status,
    sub_scholarship_id 
    }) => {
    const { search_keys, search_value } = search
    return await this.fetchData(
        API_CONFIG.END_POINTS.DONORS.APPLICATIONS.CREATE_BULK_APPLICATION(accountId, scholarshipId),
        {
            method: 'POST',
            headers: {
                'Authorization': token
            },
            body: {
                removed_application_ids,
                selected_application_ids,
                is_all_selected,
                primary_tab: 'Application',
                application_status: status,
                sub_scholarship_id,
                search_keys,
                search_value
            }
        }
    );
}

    // add export csv or pdf for applicants

    singleCSVPDFExport = async ({ token, accountId, application_id, type, userContext }) => {
        let endPoint;
        switch (userContext) {
            case USER_CONTEXT.DONOR:
            case USER_CONTEXT.SUPERDONOR:
                endPoint = (type === "csv") ?
                    API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.SINGLE_EXPORT_XLS(accountId, application_id)
                    :
                    (type === "pdf") ?
                        API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.SINGLE_EXPORT_PDF(accountId, application_id)
                        :
                        (type === "score") ?
                            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.SINGLE_SCORE_REPORT_EXPORT(accountId, application_id)
                            :
                            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.SINGLE_RECOMMENDER_STATUS_REPORT_EXPORT(accountId, application_id);

                break;
            case USER_CONTEXT.REVIEWER:
                endPoint = (type === "csv") ?
                    API_CONFIG.END_POINTS.REVIEW_BOARD_MEMBERS.SCHOLARSHIPS.SINGLE_EXPORT_XLS(accountId, application_id)
                    :
                    API_CONFIG.END_POINTS.REVIEW_BOARD_MEMBERS.SCHOLARSHIPS.SINGLE_EXPORT_PDF(accountId, application_id)
                break;
            default:
                return {
                    error: 'ACCESS DENIED',
                    errorMessage: 'The current user context does not have access to the requested resource.'
                }
        }

        return await this.fetchData(
            endPoint,
            {
                method: 'GET',
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    donationsSingleCSVExport = async ({ token, accountId, application_id, type, userContext }) => {
        let endPoint;
        switch (userContext) {
            case USER_CONTEXT.DONOR:
            case USER_CONTEXT.SUPERDONOR:
                endPoint = (type === "csv") ?
                    API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.SINGLE_EXPORT_XLS_DONATIONS(accountId, application_id)
                    :
                    (type === "pdf") ?
                        API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.SINGLE_EXPORT_PDF(accountId, application_id)
                        :
                        (type === "score") ?
                            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.SINGLE_SCORE_REPORT_EXPORT(accountId, application_id)
                            :
                            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.SINGLE_RECOMMENDER_STATUS_REPORT_EXPORT(accountId, application_id);

                break;
            case USER_CONTEXT.REVIEWER:
                endPoint = (type === "csv") ?
                    API_CONFIG.END_POINTS.REVIEW_BOARD_MEMBERS.SCHOLARSHIPS.SINGLE_EXPORT_XLS(accountId, application_id)
                    :
                    API_CONFIG.END_POINTS.REVIEW_BOARD_MEMBERS.SCHOLARSHIPS.SINGLE_EXPORT_PDF(accountId, application_id)
                break;
            default:
                return {
                    error: 'ACCESS DENIED',
                    errorMessage: 'The current user context does not have access to the requested resource.'
                }
        }

        return await this.fetchData(
            endPoint,
            {
                method: 'GET',
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    disbursementsSingleCSVExport = async ({ token, accountId, application_id, type, userContext }) => {
        let endPoint;
        switch (userContext) {
            case USER_CONTEXT.DONOR:
            case USER_CONTEXT.SUPERDONOR:
                endPoint = (type === "csv") &&
                    API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.SINGLE_EXPORT_XLS_DISBURSEMENTS(accountId, application_id)
                break;
            default:
                return {
                    error: 'ACCESS DENIED',
                    errorMessage: 'The current user context does not have access to the requested resource.'
                }
        }

        return await this.fetchData(
            endPoint,
            {
                method: 'GET',
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Update an Application Section/Step.
     * @param {string} token
     * @param {string} accountId
     * @param {string} scholarshipId
     * @param {object} formattedStepData
     * @param {string} applicationStepId
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    updateApplicationStep = async ({ token, accountId, scholarshipId, formattedStepData, applicationStepId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.APPLICATIONS.UPDATE(accountId, scholarshipId, applicationStepId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT',
                body: {
                    ...formattedStepData
                }
            }
        );
    }

    /**
     * Create a Recommendation template.
     * @param {string} token
     * @param {string} accountId
     * @param {string} scholarshipId
     * @param {object} formattedStepData
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    createRecommendation = async ({ token, accountId, scholarshipId, formattedStepData }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.RECOMMENDATIONS.CREATE(accountId, scholarshipId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: {
                    ...formattedStepData
                }
            }
        );
    }

    /**
     * Create a scorecard.
     * @param {string} token
     * @param {string} accountId
     * @param {string} scholarshipId
     * @param {object} formattedScorecardData
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    createScoreCard = async ({ token, accountId, scholarshipId, formattedScorecardData }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.SCORECARDS.CREATE(accountId, scholarshipId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: {
                    ...formattedScorecardData
                }
            }
        );
    }

    /**
     * Update an existing scorecard.
     * @param {string} token
     * @param {string} accountId
     * @param {string} scholarshipId
     * @param {string} scorecardId
     * @param {object} formattedScorecardData
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    updateScoreCard = async ({ token, accountId, scholarshipId, scorecardId, formattedScorecardData }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.SCORECARDS.UPDATE(accountId, scholarshipId, scorecardId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT',
                body: {
                    ...formattedScorecardData
                }
            }
        );
    }
    /**
     * Update an existing scorecard.
     * @param {string} token
     * @param {string} accountId
     * @param {string} scholarshipId
     * @param {string} scorecardId
     * @param {object} formattedScorecardData
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    deleteScoreCard = async ({ token, accountId, scholarshipId, scorecardId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.SCORECARDS.DELETE(accountId, scholarshipId, scorecardId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'DELETE'
            }
        );
    }
    //delete Application Step
    deleteApplicationStep = async ({ token, accountId, scholarshipId, applicationId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.APPLICATIONS.DELETE_STEP(accountId, scholarshipId, applicationId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'DELETE'
            }
        );
    }

    /**
     * Fetches a single scholarship for editing.
     * @param {string} token
     * @param {string} accountId - SFID
     * @param {string} scholarshipId
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchEditScholarship = async ({ token, accountId, scholarshipId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.EDIT(accountId, scholarshipId),
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Fetches a all RBM.
     * @param {string} token
     * @param {string} accountId - SFID
     * @param {string} scholarshipId
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchRBMForScholarship = async ({ token, accountId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.GET_ALL_RBM(accountId),
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Fetches a all RBM.
     * @param {string} token
     * @param {string} accountId - SFID
     * @param {string} scholarshipId
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    updateRBMForScholarship = async ({ token, accountId, scholarshipId, scorecardId, IDs }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.UPDATE_RBM(accountId, scholarshipId, scorecardId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT',
                body: { rbm_contact_ids: IDs }
            }
        );
    }

    /**
     * Update an existing scorecard.
     * @param {string} token
     * @param {string} accountId
     * @param {string} scholarshipId
     * @param {string} scorecardId
     * @param {object} formattedScorecardData
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    acceptAgreement = async ({ token, data }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.TERMS_AND_CONDITION(),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT',
                body: data
            }
        );
    }

    /**
     * Fetches scholarships without any options or not relying on any account data
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchAllScholarships = async () => {
        return await this.fetchData(API_CONFIG.END_POINTS.APPLICANT.SCHOLARSHIPS.GET_ALL(), {});
    }

    /**
     * Fetches a single scholarship by an id
     * @param {Number} id
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchScholarshipById = async ({ token, scholarshipId = '' }) => {
        const url = API_CONFIG.END_POINTS.APPLICANT.SCHOLARSHIPS.GET(scholarshipId);
        return await this.fetchData(url, {
            headers: {
                'Authorization': token
            }
        });
    }

    /**
     * check applicant account which used
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    checkApplicantAccount = async () => {
        let param = ''
        if (cookies('context').marketPlace) {
            param = { account_id: cookies('context').marketPlace }
        }
        if (cookies('currentAccount').currentAccount) {
            param = { account_id: cookies('currentAccount').currentAccount }
        }
        return param;
    }

    /**
     * Fetches scholarships for a donor.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {object} options - for page, search and sort
     * @param {object} userContext - determines which endpoint is used
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchScholarships = async ({ token, accountId = '', options = {}, userContext = USER_CONTEXT.DONOR, filters = {}, searchValue, route = '', donation_forms }) => {
        const { page, search, sort, status, per_page } = options;
        const searchParamDonor = search ? { ...search } : {};
        const sortParam = sort ? { order_with: sort } : {};
        const statusParam = status ? { scholarship_status: status } : {};
        const filterParam = filters ? { scholarship_filters: filters } : {};
        const searchParam = searchValue ? { search_value: searchValue } : {};
        var accountParam = (route) ? { account_id: route } : await this.checkApplicantAccount()

        let endPoint;
        switch (userContext) {
            case USER_CONTEXT.DONOR:
            case USER_CONTEXT.SUPERDONOR:
                endPoint = API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.GET_ALL(accountId);
                break;
            case USER_CONTEXT.REVIEWER:
                endPoint = API_CONFIG.END_POINTS.REVIEW_BOARD_MEMBERS.SCHOLARSHIPS.GET_ALL(accountId);
                break;
            default:
                endPoint = API_CONFIG.END_POINTS.APPLICANT.SCHOLARSHIPS.GET_ALL();
        }

        return await this.fetchData(
            endPoint,
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    per_page: per_page || 10,
                    ...searchParam,
                    ...sortParam,
                    ...statusParam,
                    ...filterParam,
                    ...searchParamDonor,
                    ...accountParam,
                    donation_forms,
                }
            }
        );
    }

    /**
     * Fetches scholarships for a applicant.
     * @param {string} token
     * @param {object} options - for page, search and sort
     * @param {object} userContext - determines which endpoint is used
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchSavedScholarships = async ({ token, options = {}, filters = {} }) => {
        const { page, search, sort, status } = options;
        const searchParam = search ? { ...search } : {};
        const sortParam = sort ? { order_with: sort } : {};
        const statusParam = status ? { scholarship_status: status } : {};
        const filterParam = filters ? { scholarship_filters: filters } : {};

        let endPoint = API_CONFIG.END_POINTS.APPLICANT.SAVEDSCHOLARSHIPS.GET_ALL();

        return await this.fetchData(
            endPoint,
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    ...searchParam,
                    ...sortParam,
                    ...statusParam,
                    ...filterParam,
                    ...await this.checkApplicantAccount()
                }
            }
        );
    }

    /**
     * Fetches applicant saved scholarship update.
     * @param {string} token
     * @param {object} data to be updated
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    updateSavedScholarships = async ({ token, ID = '' }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.SAVEDSCHOLARSHIPS.UPDATE(ID),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT',
                params: await this.checkApplicantAccount()
            }
        );
    }

    /**
     * Fetches applicant saved scholarship remove.
     * @param {string} token
     * @param {object} data to be updated
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    removeSavedScholarships = async ({ token, ID = '' }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.SAVEDSCHOLARSHIPS.REMOVE(ID),
            {
                headers: {
                    'Authorization': token
                },
                method: 'DELETE'
            }
        );
    }

    /**
     * Fetches application details for the specified scholarship.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId
     * @param {string} applicationid
     * @param {string} userContext
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchApplicationDetails = async ({ token, accountId = '', scholarshipId, applicationId, userContext = USER_CONTEXT.DONOR }) => {
        let endPoint;

        switch (userContext) {
            case USER_CONTEXT.REVIEWER:
                endPoint = API_CONFIG.END_POINTS.REVIEW_BOARD_MEMBERS.APPLICATIONS.GET_SINGLE(accountId, scholarshipId, applicationId);
                break;
            case USER_CONTEXT.DONOR:
            case USER_CONTEXT.SUPERDONOR:
                endPoint = API_CONFIG.END_POINTS.DONORS.APPLICATIONS.GET_SINGLE(accountId, scholarshipId, applicationId);
                break;
            default:
                return {
                    error: 'ACCESS DENIED',
                    errorMessage: 'The current user context does not have access to the requested resource.'
                }
        }

        return await this.fetchData(
            endPoint,
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    };

    /**
     * Fetches application review scores details for the specified application.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId
     * @param {string} applicationid
     * @param {string} userContext
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchApplicationScoreRounds = async ({ token, accountId = '', scholarshipId, applicationId, userContext = USER_CONTEXT.DONOR }) => {
        let endPoint;

        switch (userContext) {
            case USER_CONTEXT.DONOR:
            case USER_CONTEXT.SUPERDONOR:
                endPoint = API_CONFIG.END_POINTS.DONORS.APPLICATION_SCORE_ROUNDS.FETCH_ALL(accountId, scholarshipId, applicationId);
                break;
            default:
                return {
                    error: 'ACCESS DENIED',
                    errorMessage: 'The current user context does not have access to the requested resource.'
                }
        }

        return await this.fetchData(
            endPoint,
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    };

    /**
     * Fetches application review scores details for the specified application.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId
     * @param {string} applicationid
     * @param {string} Round Id => application score round ID
     * @param {string} userContext
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchApplicationScoreRoundDetails = async ({ token, accountId = '', scholarshipId, applicationId, roundId, userContext = USER_CONTEXT.DONOR }) => {
        let endPoint;

        switch (userContext) {
            case USER_CONTEXT.DONOR:
            case USER_CONTEXT.SUPERDONOR:
                endPoint = API_CONFIG.END_POINTS.DONORS.APPLICATION_SCORE_ROUNDS.FETCH_ONE(accountId, scholarshipId, applicationId, roundId);
                break;
            default:
                return {
                    error: 'ACCESS DENIED',
                    errorMessage: 'The current user context does not have access to the requested resource.'
                }
        }

        return await this.fetchData(
            endPoint,
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    };
    /**
     * Fetches application review scores details for the specified application.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId
     * @param {string} applicationid
     * @param {string} userContext
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchApplicationScores = async ({ token, accountId = '', scholarshipId, applicationId, userContext = USER_CONTEXT.DONOR }) => {
        let endPoint;

        switch (userContext) {
            case USER_CONTEXT.REVIEWER:
                endPoint = API_CONFIG.END_POINTS.REVIEW_BOARD_MEMBERS.APPLICATION_SCORE.FETCH_ALL(accountId, scholarshipId, applicationId);
                break;
            default:
                return {
                    error: 'ACCESS DENIED',
                    errorMessage: 'The current user context does not have access to the requested resource.'
                }
        }

        return await this.fetchData(
            endPoint,
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    };

    /**
     * Create team notes with mentioned recepient on application score.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId
     * @param {string} applicationid
     * @param {string} userContext
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    createRBMTeamNote = async ({ token, accountId = '', scholarshipId, applicationId, data = {}, userContext = USER_CONTEXT.REVIEWER }) => {
        let endPoint;

        switch (userContext) {
            case USER_CONTEXT.REVIEWER:
                endPoint = API_CONFIG.END_POINTS.REVIEW_BOARD_MEMBERS.TEAM_NOTE.CREATE(accountId, scholarshipId, applicationId);
                break;
            case USER_CONTEXT.DONOR:
                endPoint = API_CONFIG.END_POINTS.DONORS.APPLICATIONS.UPDATE_CLIENT_NOTE(accountId, scholarshipId, applicationId);
                break;
            default:
                return {
                    error: 'ACCESS DENIED',
                    errorMessage: 'The current user context does not have access to the requested resource.'
                }
        }

        return await this.fetchData(
            endPoint,
            {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: data
            }
        );
    };


    /**
     * Fetches application review board team members.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId
     * @param {string} applicationid
     * @param {string} userContext
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchReviewerTeamMembers = async ({ token, accountId = '', scholarshipId, scholarshipRoundId, userContext = USER_CONTEXT.REVIEWER }) => {
        let endPoint;

        switch (userContext) {
            case USER_CONTEXT.REVIEWER:
                endPoint = API_CONFIG.END_POINTS.REVIEW_BOARD_MEMBERS.TEAM_MEMBERS.FETCH_ALL(accountId, scholarshipId, scholarshipRoundId);
                break;
            default:
                return {
                    error: 'ACCESS DENIED',
                    errorMessage: 'The current user context does not have access to the requested resource.'
                }
        }

        return await this.fetchData(
            endPoint,
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    };

    /**
     * Fetches application review scores details for the specified application.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId
     * @param {string} applicationid
     * @param {string} userContext
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    updateApplicationScores = async ({ token, accountId = '', scholarshipId, applicationId, data = {}, userContext = USER_CONTEXT.REVIEWER }) => {
        let endPoint;

        switch (userContext) {
            case USER_CONTEXT.REVIEWER:
                endPoint = API_CONFIG.END_POINTS.REVIEW_BOARD_MEMBERS.APPLICATION_SCORE.SUBMIT(accountId, scholarshipId, applicationId);
                break;
            default:
                return {
                    error: 'ACCESS DENIED',
                    errorMessage: 'The current user context does not have access to the requested resource.'
                }
        }

        return await this.fetchData(
            endPoint,
            {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: data
            }
        );
    };

    /**
     * Fetches profile for user.
     * @param {string} token
     * @param {object} data to be updated
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    updateApplicationStatus = async ({ token, application, accountId, scholarshipId, applicationId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.UPDATE_APPLICATION_STATUS(accountId, scholarshipId, applicationId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT',
                body: { application: application }
            }
        );
    }

    deleteApplication = async ({ token, accountId, scholarshipId, applicationId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.DELETE_APPLICATION(accountId, scholarshipId, applicationId),
            {
                method: 'DELETE',
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Fetches all applications for the specified scholarship.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId
     * @param {object} options
     * @param {string} userContext
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchApplications = async ({ token, accountId = '', scholarshipId, options = {}, userContext = USER_CONTEXT.DONOR }) => {
        const { page, search, sort, status, per_page } = options;
        const searchParam = search ? { ...search } : {};
        const sortParam = sort ? { order_with: sort } : {};
        const statusParam = status ? { application_status: status } : {};
        let endPoint;

        switch (userContext) {
            case USER_CONTEXT.DONOR:
            case USER_CONTEXT.SUPERDONOR:
                endPoint = API_CONFIG.END_POINTS.DONORS.APPLICATIONS.GET_ALL(accountId, scholarshipId);
                break;
            case USER_CONTEXT.REVIEWER:
                endPoint = API_CONFIG.END_POINTS.REVIEW_BOARD_MEMBERS.APPLICATIONS.GET_ALL(accountId, scholarshipId);
                break;
            default:
                return {
                    error: 'ACCESS DENIED',
                    errorMessage: 'The current user context does not have access to the requested resource.'
                }
        }

        const response = await this.fetchData(
            endPoint,
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    per_page: per_page || 10,
                    ...searchParam,
                    ...sortParam,
                    ...statusParam
                }
            }
        );
        return response
    };

    /**
     * Fetches people for a donor.
     * @param {string} token
     * @param {string} applicationId - Application SFID
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    exportApplicationpdf = async ({ token, applicationId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.REVIEW_BOARD_MEMBERS.APPLICATIONS.EXPORT_PDF(applicationId),
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    }


    /**
     * Fetches people for a donor.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {object} options - for page, search and sort
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchPeople = async ({ token, accountId, options = {} }) => {
        const { page, search, sort, status, per_page } = options;
        const searchParam = search ? { ...search } : {};
        const sortParam = sort ? { order_with: sort } : {};
        const statusParam = status ? { heroku_role: status } : {};

        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.PEOPLE.GET_ALL(accountId),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    per_page: per_page || 10,
                    ...searchParam,
                    ...sortParam,
                    ...statusParam,
                    primary_tab: 'People'
                }
            }
        );
    }

    sendResetPasswordLink = async ({ token, accountId, contactId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.PEOPLE.RESET_PASSWORD_LINK(accountId, contactId),
            {
                headers: {
                    'Authorization': token
                },
                // method: 'PUT'
            }
        );
    }

    fetchClientDisbursementsProgress = async ({ token, accountId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.DISBURSEMENTS.GET_PROGRESS(accountId),
            {
                headers: {
                    'Authorization': token
                },
            }
        );
    }
    fetchClientDisbursementsDetails = async ({ token, accountId,disbursementId }) => {
      
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.DISBURSEMENTS.GET_DETAILS(accountId,disbursementId),
            {
                headers: {
                    'Authorization': token
                },
            }
        );
    }

    fetchClientDisbursements = async ({ token, accountId, options = {} }) => {
        const { page, search, sort, status,per_page, filters} = options;
        const searchParamDonor = search ? { ...search } : {};
        const searchParam = search ? { ...search } : {};
        const sortParam = sort ? { order_with: sort } : {};
        const statusParam = status ? { disbursement_status: status } : {};
        const filterParam = filters?.scholarship_id ? { selected_scholarship_ids: filters?.scholarship_id } : {};

        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.DISBURSEMENTS.GET_ALL(accountId),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    per_page:per_page||10,
                    ...searchParam,
                    ...sortParam,
                    ...searchParamDonor,
                    ...statusParam,
                    ...filterParam,
                    primary_tab: 'Disbursement',

                }
            }
        );
    }

    UpdateClientDisbursements= async ({ token, accountId, disbursementID,disbursementRecords }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.DISBURSEMENTS.UPDATE(accountId,disbursementID,disbursementRecords),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT',
                body: {disbursement:disbursementRecords}
            }
        );
    }
    fetchReportUrl = async ({ accountId, reportId, token }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.REPORTS.GET_SHARED_REPORT(accountId, reportId),
            {
                headers: {
                    'Authorization': token,
                }
            }
        )
    }

    fetchClientReports = async ({ token, accountId, options = {} }) => {
        const { page, search_value, sort, status, filters } = options;
        const searchParam = search_value ? { search_value: search_value } : {};
        const sortParam = sort ? { order_with: sort } : {};

        const filterParam = filters ? { applicant_filters: filters } : {};

        const statusParam = status ? { heroku_role: status } : {};

        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.REPORTS.GET_ALL(accountId),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    per_page: options.per_page || 10,
                    ...searchParam,
                    ...sortParam,
                    ...filterParam,
                    primary_tab: 'Report',

                }
            }
        );
    }

    fetchClientDonations = async ({ token, accountId, options = {} }) => {
        const { page, search, sort, status, filters } = options;
        const searchParam = search ? { ...search } : {};
        // const searchParam = search_value ? { search_value: search_value } : {};
        const sortParam = sort ? { order_with: sort } : {};

        const filterParam = filters?.scholarship_id ? { selected_scholarship_ids: filters.scholarship_id } : {};
        const statusParam = status ? { heroku_role: status } : {};

        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.DONATIONS.GET_ALL(accountId),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    per_page: options.per_page || 10,
                    ...searchParam,
                    ...sortParam,
                    ...filterParam,
                    primary_tab: 'Donation',

                }
            }
        );
    }

    viewDownloadClientReport = async ({ token, accountId, reportId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.REPORTS.VIEW_DOWNLOAD_REPORT(accountId, reportId),
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
   * Download multiple reports
   */
    downloadMultipleReports = async ({ token, accountId, selected_report_ids }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.REPORTS.MULTIPLE_REPORT_DOWNLOAD(accountId),
            {

                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: {
                    selected_report_ids
                }
            }
        );
    }

    CreteAlaskaAward= async ({ token, accountId, scholarshipId, applicationId, alaskaAwardDetails }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.ALASKA_AWARD.CREATE(accountId, scholarshipId, applicationId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: alaskaAwardDetails
            }
        );
    }

    fetchAlaskaAward= async ({ token, accountId, scholarshipId, applicationId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.ALASKA_AWARD.GET_ALASKA_AWARD(accountId, scholarshipId, applicationId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'GET',
            }
        );
    }

    /**
     * Fetches profile for user.
     * @param {string} token
     * @param {object} data to be updated
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    updateProfile = async ({ token, data = {} }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.PROFILE.UPDATE(),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT',
                body: data
            }
        );
    }

    /**
     * Fetches profile for user.
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchProfile = async ({ token }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.PROFILE.FETCH(),
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Fetches Account stats.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchAccountStats = async ({ token, accountId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.ACCOUNT_STATS.GET_ALL(accountId),
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    frontendToSalesforceSync = async ({ token, page }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.FRONTENDTOSALESFORCESYNC(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                }
            }
        );
    }

    frontendApplicationSyncToElements = async ({ token, page, recordsPerPage }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.FRONTENDAPPLICATIONSYNCTOELEMENTS(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    per_page: recordsPerPage
                }
            }
        );
    }

    frontendContactSyncToElements = async ({ token, page, recordsPerPage }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.FRONTENDCONTACTSYNCTOELEMENTS(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    per_page : recordsPerPage
                }
            }
        );
    }

    rowsInFrontend = async ({ token, page }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.ROWSINFRONTENDDATABASE(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                }
            }
        );
    }

    herokuConnectErrors = async ({ token, page, obj = {} }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.HEROKUCONNECTERRORS(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    trigger_log_filters: JSON.stringify(obj)
                }
            }
        );
    }

    herokuConnectSingleError = async ({ token, id }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.HEROKUERRORDETAILS(id),
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    RetrySyncHerokuConnect = async ({ token, id }) => {

        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.RETRYSYNCHEROKUCONNECT(id),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT'
            }
        );

    }

    SSODetailsAdmin = async ({ token, accountId }) => {

        // let str = accountId.toString();


        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.SSODETAILSADMIN(accountId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'GET',
            }
        )
    }

    ssoDetailsList = async ({ token }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.SSOACCOUNTLIST(),
            {
                headers: {
                    'Authorization': token
                },
                method: 'GET',
            }
        )
    }

    copyLeaksScan = async ({ token, scholarship_Id }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.COPYLEAKSSCAN(scholarship_Id),
            {
                headers: {
                    'Authorization': token
                },
                method: 'GET',
            }
        )
    }

    portfolioProgramExport = async ({ token, scholarship_Id }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.PORTFOLIOPROGRAMCHECKEREXPORT(scholarship_Id),
            {
                headers: {
                    'Authorization': token
                },
                method: 'GET',
            }
        )
    }

    achInfoExport = async ({ token, filters }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.DISBURSEMENTS_ACH_INFO_EXPORT(),
            {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: filters
            }
        )
    }


    ssoDataSend = async ({ token, accountId, data }) => {
        let str = accountId.toString();

        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.SSODETAILSADMIN(str),
            {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: data
            }
        )
    }
    /**
     * Fetches profile for user.
     * @param {string} token
     * @param {object} data to be updated
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    addRemoveTiles = async ({ token, accountId, data = [] }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.ACCOUNT_STATS.ADD_REMOVE_TILES(accountId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: data
            }
        );
    }


    /**
     * Fetches tasks for a donor.
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchInstitutions = async ({ value, token }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.INSTITUTE.FETCH_ALL(),
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Fetches tasks for a Applicant Profile.
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchApplicantTasks = async ({ token, options = {} }) => {
        const { page, search, sort, status } = options;
        const searchParam = search ? { ...search } : {};
        const sortParam = sort ? { order_with: sort } : {};
        const statusParam = status ? { task_status: status } : {};

        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.TASKS.GET_ALL(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    ...searchParam,
                    ...sortParam,
                    ...statusParam,
                    ...await this.checkApplicantAccount()
                }
            }
        );
    }

    /**
     * Fetches tasks for a Applicant Profile.
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    createApplicantTask = async ({ token, data }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.TASKS.CREATE(),
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: { applicant_task: data }
            }
        );
    }

    /**
     * Fetches tasks for a donor.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchTasks = async ({ token, accountId, options = {} }) => {
        const { page, search, sort, status } = options;
        const searchParam = search ? { ...search } : {};
        const sortParam = sort ? { order_with: sort } : {};
        const statusParam = status ? { task_status: status } : {};

        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.TASKS.GET_ALL(accountId),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    ...searchParam,
                    ...sortParam,
                    ...statusParam
                }
            }
        );
    }

    /**
     * Fetches contacts for a donor task.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchContacts = async ({ token, accountId, options = {} }) => {
        const { page, search, sort, status } = options;
        const searchParam = search ? { ...search } : {};
        const sortParam = sort ? { order_with: sort } : {};
        const statusParam = status ? { search_value: status } : {};

        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.TASKS.CONTACT(accountId),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    ...searchParam,
                    ...sortParam,
                    ...statusParam
                }
            }
        );
    }

    /**
     * Fetches Task for a donor task.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchTaskDetail = async ({ token, accountId = '', task_id, options = {}, userContext }) => {
        const { page, search, sort, status } = options;
        const searchParam = search ? { ...search } : {};
        const sortParam = sort ? { order_with: sort } : {};
        const statusParam = status ? { search_value: status } : {};

        let endPoint;
        switch (userContext) {
            case USER_CONTEXT.DONOR:
            case USER_CONTEXT.SUPERDONOR:
                endPoint = API_CONFIG.END_POINTS.DONORS.TASKS.GET_SINGLE(accountId, task_id);
                break;
            case USER_CONTEXT.APPLICANT:
                endPoint = API_CONFIG.END_POINTS.APPLICANT.TASKS.GET_SINGLE(task_id);
                break;
            default:
                return {
                    error: 'ACCESS DENIED',
                    errorMessage: 'The current user context does not have access to the requested resource.'
                }
        }

        return await this.fetchData(
            endPoint,
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    ...searchParam,
                    ...sortParam,
                    ...statusParam
                }
            }
        );
    }

    /**
     * Create Task for a Donor.
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    createDonorTask = async (token, accountId, data) => {
        return await this.FormDataPost(
            API_CONFIG.END_POINTS.DONORS.TASKS.CREATE(accountId),
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: data
            }
        );
    }

    /**
     * Fetches Update completed task for a donor.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} taskId - Account TaskId
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    updateTasks = async ({ token, accountId, task_id, status }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.TASKS.UPDATE(accountId, task_id),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT',
                body: {
                    donor_task: {
                        status: status
                    }
                }
            }
        );
    }

    /**
     * Fetches Update completed task for a donor.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} taskId - Account TaskId
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    editTasks = async (token, accountId, task_id, data) => {
        return await this.FormDataPost(
            API_CONFIG.END_POINTS.DONORS.TASKS.EDIT(accountId, task_id),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT',
                body: data
            }
        );
    }

    /**
     * Fetches Update completed task for a donor.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} taskId - Account TaskId
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    updateApplicantTasks = async ({ token, task_id, data }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.TASKS.UPDATE(task_id),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT',
                body: {
                    applicant_task: data
                }
            }
        );
    }

    /**
     * Fetch notification setting for account.
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchAccountNotificationSetting = async ({ token }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.NOTIFICATIONS_SETTING.GET_ALL(),
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Update all notification view status to Viewed.
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    touchAllNotifications = async ({ token }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.NOTIFICATIONS.TOUCH_ALL(),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT'
            }
        );
    }


    /**
     * Update notification setting for logged-in user.
     * @param {string} token
     * @param {object} data to be updated
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    updateNotificationSetting = async ({ token, data = {} }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.NOTIFICATIONS_SETTING.UPDATE(),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PATCH',
                body: data
            }
        );
    }

    /**
     * Fetches applicants for a donor.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {object} options - for page, search and sort
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchApplicants = async ({ token, accountId, options = {}, filters }) => {
        const { page, search, sort, status } = options;
        const searchParam = search ? { ...search } : {};
        const sortParam = sort ? { order_with: sort } : {};
        const statusParam = status ? { with_status: status } : {};

        const filterParam = filters ? { applicant_filters: filters } : {};

        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.APPLICANTS.GET_ALL(accountId),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    per_page: options.per_page || 10,
                    ...searchParam,
                    ...sortParam,
                    ...statusParam,
                    ...filterParam,
                }
            }
        );
    }

    /**
     * Fetches all of the applicant disbursements.
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchApplicantDisbursements = async ({ token }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.DISBURSEMENTS.GET_ALL(),
            {
                headers: {
                    'Authorization': token
                },
                params: await this.checkApplicantAccount()
            }
        );
    }

    /**
     * Fetches all of the recommendation questions of an applicant.
     * @param {string} token
     * @param {object} options
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchApplicantRecommendationQuestions = async ({ token, recommendationid = '', options = {} }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.RECOMMENDOR.RECOMMENDATION_QUESTIONS.GET_ALL(recommendationid),
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Submit all of the recommendation questions of an applicant.
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    submitApplicantRecommendationQuestions = async ({ token, recommendationid = '', data = [], recommendation_status__c = 'Submitted' }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.RECOMMENDOR.RECOMMENDATION_QUESTIONS.UPDATE(recommendationid),
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: {
                    recommendation_questions_data: data,
                    recommendation_status__c
                }
            }
        );
    }

    // Delete document from azure server.
    deleteAzureDocument = async (deleteName) => {
        const blobServiceClient = new BlobServiceClient(`${AZURE_BASE_URL}?${SAS_TOKEN}`);
        const containerClient = blobServiceClient.getContainerClient(CONTAINER_NAME + '/imports/classic');
        try {
            const deleteResponse = await containerClient.deleteBlob(deleteName);
            return deleteResponse;
        } catch (error) {
            console.log(error, "azure delete error");
        }
    };
    

    /**
     * Delete document from cloud.
     * @param {string} id
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    deleteUploadedDocument = async ({ token, recommendationid, id, documentid }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.RECOMMENDOR.RECOMMENDATION_QUESTIONS.DELETE_DOCUMENT_NEW(recommendationid, id, documentid),
            {
                method: 'DELETE',
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Fetches all of the applicant donors.
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchApplicantDonors = async ({ token }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.DONOR.FETCH_ALL(),
            {
                headers: {
                    'Authorization': token
                },
                params: await this.checkApplicantAccount()
            }
        );
    }

    fetchApplicantDonorsModal = async ({ token }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.DONARS_INFO.FETCH_ALL(),
            {
                headers: {
                    'Authorization': token
                },
                params: await this.checkApplicantAccount()
            }
        );
    }

    /**
     * Fetches all of the applicant recommendations for a recommender.
     * @param {string} token
     * @param {object} options
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchApplicantRecommendations = async ({ token, options = {} }) => {
        const { page, search, sort, status } = options;
        const searchParam = search ? { ...search } : {};
        const sortParam = sort ? { order_with: sort } : {};
        const statusParam = status ? { recommendation_status__c: status } : {};

        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT_RECOMMENDATIONS.GET_ALL(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    ...searchParam,
                    ...sortParam,
                    ...statusParam
                }
            }
        );
    }

    /**
     * Fetches Resources for a Applicant Profile.
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchApplicantResources = async ({ token, options = {} }) => {
        const { page, search, sort, status } = options;
        const searchParam = search ? { ...search } : {};
        const sortParam = sort ? { order_with: sort } : {};
        const statusParam = status ? { task_status: status } : {};

        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.RESOURCES.GET_ALL(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    ...searchParam,
                    ...sortParam,
                    ...statusParam
                }
            }
        );
    }

    /**
     * Fetches Resources for a Applicant Profile.
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    createApplicantResource = async (token, form_data) => {
        return await this.FormDataPost(
            API_CONFIG.END_POINTS.APPLICANT.RESOURCES.CREATE(),
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: form_data
            }
        );
    }

    /**
     * Fetches Resources for a Applicant Profile.
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    updateApplicantResource = async (token, id, form_data) => {
        return await this.FormDataPost(
            API_CONFIG.END_POINTS.APPLICANT.RESOURCES.UPDATE(id),
            {
                method: 'PUT',
                headers: {
                    'Authorization': token
                },
                body: form_data
            }
        );
    }
    /**
     * Fetch result of removing a resource.
     * @param {string} token
     * @param {string} resourceId - Account ID
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    removeApplicantResource = async ({ token, ID }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.RESOURCES.REMOVE(ID),
            {
                method: 'DELETE',
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Fetches accounts associated with a donor.
     * @param {string} token
     * @param {string} userContext
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchAccounts = async ({ token, userContext, currentAccountId }) => {
        let endPoint;
        switch (userContext) {
            case USER_CONTEXT.DONOR:
            case USER_CONTEXT.SUPERDONOR:
                endPoint = API_CONFIG.END_POINTS.DONORS.ACCOUNTS.GET_ALL();
                break;
            case USER_CONTEXT.REVIEWER:
                endPoint = API_CONFIG.END_POINTS.REVIEW_BOARD_MEMBERS.ACCOUNTS.GET_ALL()
                break;
            case USER_CONTEXT.APPLICANT:
                endPoint = API_CONFIG.END_POINTS.APPLICANT.ACCOUNTS.GET_ALL()
                break;
            default:
                return [];
        }

        return await this.fetchData(
            endPoint,
            {
                headers: {
                    'Authorization': token
                },
                params: currentAccountId && { account_id: currentAccountId }
            }
        );
    };

    /**
     * Fetch result of adding a reviewer.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId - Scholarship SFID
     * @param {string} reviewBoardMemberId -
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    updateAccountDetails = async ({ token, accountId, data }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.ACCOUNTS.UPDATE(accountId),
            {
                method: 'PUT',
                headers: {
                    'Authorization': token
                },
                body: data
            }
        );
    }

    /**
     * Fetch result of removing a resource.
     * @param {string} token
     * @param {string} resourceId - Account ID
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    removeAccountDocument = async ({ token, id, documentID }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.ACCOUNTS.REMOVE_DOCUMENT(id, documentID),
            {
                method: 'DELETE',
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Fetches notifications for the current user.
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchNotifications = async ({ token }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.NOTIFICATIONS.GET_ALL(),
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    };

    /**
     * Fetches all the board members including ones currently associated to the
     * specified scholarship.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId - Scholarship SFID
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchReviewBoardMembers = async ({ token, accountId, scholarshipId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.REVIEW_BOARD_MEMBERS.GET_ALL(accountId, scholarshipId),
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Fetch result of adding a reviewer.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId - Scholarship SFID
     * @param {string} reviewBoardMemberId -
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    addReviewBoardMember = async ({ token, accountId, scholarshipId, reviewBoardMemberId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.REVIEW_BOARD_MEMBERS.ADD(accountId, scholarshipId, reviewBoardMemberId),
            {
                method: 'PUT',
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Fetch result of removing a reviewer.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId - Scholarship SFID
     * @param {string} reviewBoardMemberId -
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    removeReviewBoardMember = async ({ token, accountId, scholarshipId, reviewBoardMemberId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.REVIEW_BOARD_MEMBERS.REMOVE(accountId, scholarshipId, reviewBoardMemberId),
            {
                method: 'PUT',
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Fetch result of nudging a reviewer.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId - Scholarship SFID
     * @param {string} reviewBoardMemberId -
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    nudgeReviewBoardMember = async ({ token, accountId, scholarshipId, reviewBoardMemberId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.REVIEW_BOARD_MEMBERS.NUDGE(accountId, scholarshipId, reviewBoardMemberId),
            {
                method: 'PUT',
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Search available members by email. TODO: Update this once completed
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId - Scholarship SFID
     * @param {string} email
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    searchReviewBoardMember = async ({ token, accountId, scholarshipId, email }) => {
        let emailData = { "email": email }
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.REVIEW_BOARD_MEMBERS.SEARCH(accountId, scholarshipId),
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: {
                    contact: emailData
                }
            }
        )
    }

    /**
     * Fetch result of reviewer reassignment.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId - Scholarship SFID
     * @param {string} reviewBoardMemberId -
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    reassignReviewBoardMember = async ({ token, accountId, scholarshipId, reviewBoardMemberId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.REVIEW_BOARD_MEMBERS.REASSIGN(accountId, scholarshipId, reviewBoardMemberId),
            {
                method: 'PUT',
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Invites a user to the review board of the specified scholarship.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId - Scholarship SFID
     * @param {string} firstName
     * @param {string} lastName
     * @param {string} email
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    inviteReviewBoardMember = async ({ token, accountId, scholarshipId, firstName, lastName, email }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.REVIEW_BOARD_MEMBERS.INVITE(accountId, scholarshipId),
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: {
                    contact: {
                        email,
                        firstname: firstName,
                        lastname: lastName
                    }
                }
            }
        );
    }

    /**
     * Fetches all applications
     * specified scholarship.
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */

    fetchApplicantApplications = async ({ token }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.APPLICATIONS.GET_ALL(),
            {
                headers: {
                    'Authorization': token
                },
                params: await this.checkApplicantAccount()
            }
        );
    }

    /**
     * Fetches all conversations of active user
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchConversations = async ({ token }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.CONVERSATIONS.GET_ALL_CONVERSATION(),

            {
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Fetches all conversations of active user
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchConversationContacts = async ({ token, value = '' }) => {

        return await this.fetchData(
            API_CONFIG.END_POINTS.CONVERSATIONS.GET_NEW_CONVERSATION_CONTACT(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    search_value: value,
                    ...await this.checkApplicantAccount()
                }
            }
        );
    }

    /**
     * Fetches all conversations of active user
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    updateConversation = async ({ token, conversationId = '' }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.CONVERSATIONS.UPDATE_CONVERASATION(conversationId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT'
            }
        );
    }

    /**
     * Fetches all conversations of active user
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    SalesForceCallbackRedireact = async ({ code }) => {
        var url = API_CONFIG.END_POINTS.ADMIN.LOGIN()
        return await this.fetchData(
            url,
            {
                method: 'POST',
                body: {
                    code
                },
            }
        );
    }

    /**
     * Fetches user detail
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    userDetails = async ({ token, data }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.USER_DETAIL(),
            {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: data
            }
        );
    }

    /**
     * Fetches all users
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    usersListing = async ({ token, options = {}, userRole }) => {
        const { page, search, sort, status } = options;
        const searchParam = search ? { ...search } : {};
        const sortParam = sort ? { order_with: sort } : {};
        const role = { user_role: userRole }
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.USER_LIST(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    ...searchParam,
                    ...sortParam,
                    ...role
                }
            }
        );
    }

    /**
     * Fetches all duplicate contact
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    duplicatecontactListing = async ({ token, options = {} }) => {
        const { page, search, sort, status } = options;
        const searchParam = search ? { ...search } : {};
        const sortParam = sort ? { order_with: sort } : {};
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.DUPLICATE_CONTACTS_LIST(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    ...searchParam,
                    ...sortParam,
                }
            }
        );
    }

    /**
     * Fetch result of removing a duplicate contact.
     * @param {string} token
     * @param {string} resourceId - Account ID
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    removeDuplicateContact = async ({ token, duplicateEmail }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.REMOVE_DUPLICATE_CONTACT(),
            {
                method: 'DELETE',
                headers: {
                    'Authorization': token
                },
                params: duplicateEmail
            }
        );
    }

    /**
     * Fetches all  user acitivites
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    userActivity = async ({ token, userEmail, page }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.USER_ACTIVITIES(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    user_email: userEmail,
                    page: page || 1
                }
            }
        );
    }

    /**
     * Fetches all  user acitivites
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    loginHistory = async ({ token, options = {} }) => {
        const { page, search, sort, status } = options;
        const searchParam = search ? { ...search } : {};
        const sortParam = sort ? { order_with: sort } : {};

        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.LOGIN_HISTORY(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    ...searchParam,
                    ...sortParam,
                }
            }
        );
    }
    /**
     * Fetches all  user acitivites
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    exportLoginHistory = async ({ token }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.LOGIN_HISTORY(),
            {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: {}
            }
        );
    }

    /**
     * Fetches all  user acitivites
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    userEmailHistory = async ({ token, userEmail, page }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.USER_EMAIL_HISTORY(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    user_email: userEmail,
                    page: page || 1
                }
            }
        );
    }

    /**
     * Fetches all  user acitivites
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    recommenderList = async ({ token, applicationName, page }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.APPLICATION_RECOMMENDERS(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    application_name: applicationName,
                    page: page || 1
                }
            }
        );
    }

    /**
     * Fetches all  user acitivites
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    recommenderUpdate = async ({ token, recommenderHerokuId, recommenderData }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.UPDATE_APPLICATION_RECOMMENDERS(recommenderHerokuId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT',
                body: { recommender: recommenderData }
            }
        );
    }
    //  Recommender Delete for Client persona
    recommenderDeleteForClient = async ({ token, accountId, scholarshipId, applicationId, applicationStepId, recommenderId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.APPLICATIONS.DELETE_APPLICATION_RECOMMENDERS_CLIENT(accountId, scholarshipId, applicationId, applicationStepId, recommenderId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'DELETE'
            }
        );
    }


    //  Recommender Add for Client persona

    recommenderAddForClient = async ({ token, recommenderData, accountId, scholarshipId, applicationId, applicationStepId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.APPLICATIONS.ADD_APPLICATION_RECOMMENDERS_CLIENT(accountId, scholarshipId, applicationId, applicationStepId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: { recommender: recommenderData }
            }
        );
    }
    /**
     * Fetches all  user acitivites
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    applicationDoc = async ({ token, applicationName, page }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.APPLICATION_DOCUMENT(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    application_name: applicationName,
                    page: page || 1
                }
            }
        );
    }

    //  Recommender Update for Client persona

    recommenderUpdateForClient = async ({ token, recommendationid, recommenderData, accountId, scholarshipId, applicationId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.APPLICATIONS.UPDATE_APPLICATION_RECOMMENDERS_CLIENT(accountId, scholarshipId, applicationId, recommendationid),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT',
                body: { recommender: recommenderData }
            }
        );
    }
    /**
     * Fetches all  user acitivites
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    applicationDoc = async ({ token, applicationName, page }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.APPLICATION_DOCUMENT(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    application_name: applicationName,
                    page: page || 1
                }
            }
        );
    }

    /**
     * Fetches all  user acitivites
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    applicationDocUpdate = async ({ token, recommenderId, recommenderData }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.UPDATE_APPLICATION_DOCUMENT(recommenderId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PUT',
                body: { recommender: recommenderData }
            }
        );
    }
    /**
     * Fetches all  user acitivites
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    clientApplicationDoc = async ({ token, accountId, scholarshipId, applicationId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.APPLICATIONS.GET_APPLICATION_SINGLE(accountId, scholarshipId, applicationId),
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Add ApplicationDocument by Admin.
     * @param {string} id
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    clientAddApplicationDocument = async ({ token, accountId, scholarshipId, applicationId, applicationData }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.APPLICATIONS.UPDATE_APPLICATION_SINGLE(accountId, scholarshipId, applicationId),
            {
                method: 'PUT',
                headers: {
                    'Authorization': token
                },
                body: { application_data: applicationData }
            }
        );
    }

    /**
     * Fetches all  user acitivites
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchClientNotes = async ({ token, userContext, accountId, scholarshipId, applicationId }) => {
        let endPoint;
        switch (userContext) {
            case USER_CONTEXT.DONOR:
                endPoint = API_CONFIG.END_POINTS.DONORS.APPLICATIONS.GET_CLIENT_NOTE(accountId, scholarshipId, applicationId);
                break;
            case USER_CONTEXT.REVIEWER:
                endPoint = API_CONFIG.END_POINTS.REVIEW_BOARD_MEMBERS.APPLICATIONS.GET_CLIENT_NOTE(accountId, scholarshipId, applicationId)
                break;
            default:
                return [];
        }

        return await this.fetchData(
            endPoint,
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Add ApplicationDocument by Admin.
     * @param {string} id
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    AddClientNotes = async ({ token, accountId, scholarshipId, applicationId, applicationData }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.APPLICATIONS.UPDATE_CLIENT_NOTE(accountId, scholarshipId, applicationId),
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: { application_data: applicationData }
            }
        );
    }

    /**
     * Fetches job acitivites
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    jobActivity = async ({ token, jobName, options = {} }) => {
        const { page, search, sort, status } = options;
        const sortParam = sort ? { order_with: sort } : {};
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.JOB_ACTIVITY(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    job_activity: jobName,
                    ...sortParam,
                }
            }
        );
    }

    /**
     * Fetches bulkPasswordUpdateCSV
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    bulkPasswordUpdateCSV = async (token, form_data) => {
        return await this.FormDataPost(
            API_CONFIG.END_POINTS.ADMIN.BULKPASSWORDUPDATECSV(),
            {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: form_data
            }
        );
    }

    /**
     * Fetches bulkPromoteUser
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    bulkPromoteUser = async (token, form_data) => {
        return await this.FormDataPost(
            API_CONFIG.END_POINTS.ADMIN.BULK_PROMOTE_USERS(),
            {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: form_data
            }
        );
    }

    /**
     * Fetches job acitivites
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    failedUserRecords = async ({ token, delayedJobQueueId, currentPage }) => {

        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.FAILED_USER_RECORDS(delayedJobQueueId),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: currentPage
                }
            }
        );
    }

    /**
     * Fetches promot user
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    promoteUser = async ({ token, data, context = 'ADMIN' }) => {
        let endPoint = API_CONFIG.END_POINTS.ADMIN.PROMOTE_USERS()
        if (context === 'DONOR') {
            endPoint = API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.PROMOTE_AS_APPLICANT()
        } else if (context === 'ANY') {
            endPoint = API_CONFIG.END_POINTS.AUTH.PROMOTE_AS_APPLICANT()
        }
        return await this.fetchData(
            endPoint,
            {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: data
            }
        );
    }

    /**
     * Fetches all active users
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    activeUsers = async ({ token, options = {} }) => {
        const { page, search, sort, status } = options;
        const sortParam = sort ? { order_with: sort } : {};
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.ACTIVE_USERS(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    ...sortParam,
                }
            }
        );
    }

    /**
     * Fetches all active users
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    chartData = async ({ token }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.CHART_DATA(),
            {
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * create  conversations of active user
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    createConversations = async ({ token, id }) => {
        var conversation = {
            recipient_id: id
        }

        const res = await this.fetchData(
            API_CONFIG.END_POINTS.CONVERSATIONS.GET_ALL_CONVERSATION(),
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: {
                    conversation
                }
            }
        );
        return res
    }

    /**
    * create  conversations of active user

    * @param {string} token
    * @param {string} conversation_id
    * @param {string} text
    * @param {string} user_id
    * @returns {Promise<{errorMessage: *, error: boolean}|*>}
    */
    createMessage = async ({ token, text, conversation_id }) => {
        var message = {
            text: text
        }
        return await this.fetchData(
            API_CONFIG.END_POINTS.CONVERSATIONS.CREATE_MESSAGE(conversation_id),

            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: { message }
            }
        );
    }

    /**
     * Fetches all the Gradebook
     * specified Gradebook.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId - Scholarship SFID
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */

    fetchGradeBook = async ({ token, account__c, sendAccountId }) => {

        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.GRADEBOOK.GET_ALL(),
            !sendAccountId ? {
                headers: {
                    'Authorization': token
                }
            } : {
                headers: {
                    'Authorization': token
                },
                params: { account_id: account__c }
            }
        );
    }

    /**
     * Fetch result of adding a reviewer.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId - Scholarship SFID
     * @param {string} reviewBoardMemberId -
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    addGradeBook = async ({ token, form_data }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.GRADEBOOK.CREATE(),
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: form_data
            }
        );
    }

    /**
     * Fetch result of removing a reviewer.
     * @param {string} token
     * @param {string} accountId - Account SFID
     * @param {string} scholarshipId - Scholarship SFID
     * @param {string} reviewBoardMemberId -
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    editGradeBook = async ({ token, form_data, ID }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.GRADEBOOK.UPDATE(ID),
            {
                method: 'PUT',
                headers: {
                    'Authorization': token
                },
                body: form_data
            }
        );
    }

    /**
     * Delete document from cloud.
     * @param {string} id
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    deleteUploadedDocumentGradeBook = async ({ token, gradebookId, documentId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.GRADEBOOK.REMOVE_DOCUMENT(gradebookId, documentId),
            {
                method: 'DELETE',
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Delete document from cloud.
     * @param {string} id
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    deleteGradeBook = async ({ token, gradebookId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.GRADEBOOK.REMOVE(gradebookId),
            {
                method: 'DELETE',
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Submit scholarship application data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    submitScholarship = async ({ token, scholarshipId, applicationStepId, applicationsData, applicationId }) => {

        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.SCHOLARSHIPS.SUBMIT_SCHOLARSHIP(scholarshipId, applicationStepId),
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: applicationsData,
                params: { application_id: applicationId }
            }
        );
    }

    /**
     * Fetches scholarship application data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchApplyScholarship = async ({ token, scholarshipId, applicationStepId, applicationId }) => {
        let endPoint = API_CONFIG.END_POINTS.APPLICANT.SCHOLARSHIPS.APPLY_SCHOLARSHIP_WITHOUT_LOGIN(scholarshipId, applicationStepId)
        if (token) {
            endPoint = API_CONFIG.END_POINTS.APPLICANT.SCHOLARSHIPS.APPLY_SCHOLARSHIP(scholarshipId, applicationStepId)
        }
        return await this.fetchData(
            endPoint,
            {
                method: 'GET',
                headers: {
                    'Authorization': token
                },
                params: { application_id: applicationId }
            }
        );
    }

    notifyAccountManager = async ({ token, accountId }) => {
        let endPoint = API_CONFIG.END_POINTS.DONORS.ACCOUNTS.NOTIFY_ACCOUNT_MANAGER(accountId)

        return await this.fetchData(
            endPoint,
            {
                method: 'GET',
                headers: {
                    'Authorization': token
                },
            }
        );
    }

    /**
     * Fetches resume scholarship application data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchResumeScholarship = async ({ token, scholarshipId, applicationId, actionSubmit, userContext = 'APPLICANT' }) => {
        let endPoint = API_CONFIG.END_POINTS.APPLICANT.SCHOLARSHIPS.RESUME_SCHOLARSHIP(scholarshipId, applicationId);
        if (actionSubmit) {
            endPoint = API_CONFIG.END_POINTS.APPLICANT.SCHOLARSHIPS.SUBMIT_APPLICATION(scholarshipId, applicationId);
        }

        const { USER_ROLE_CONTEXT } = MAIN_CONFIG;
        const roleContext = USER_ROLE_CONTEXT.find(i => i.context === userContext);

        return await this.fetchData(
            endPoint,
            {
                method: 'GET',
                headers: {
                    'Authorization': token
                },
                params: { current_role: roleContext.role }
            }
        );

    }

    /**
     * Fetches resume scholarship application data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchStaffApplicationSummary = async ({ token, applicationId, userContext }) => {
        let endPoint = API_CONFIG.END_POINTS.UNIVERSITYSTAFF.APPLICATIONS.SINGLE_APPLICATION(applicationId);

        const { USER_ROLE_CONTEXT } = MAIN_CONFIG;
        const roleContext = USER_ROLE_CONTEXT.find(i => i.context === userContext);

        return await this.fetchData(
            endPoint,
            {
                method: 'GET',
                headers: {
                    'Authorization': token
                },
                params: { current_role: roleContext.role }
            }
        );

    }

    /**
     * Fetches resume scholarship application data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    updateStaffApplicationSummary = async ({ token, applicationId, applicationsData, userContext }) => {
        const { USER_ROLE_CONTEXT } = MAIN_CONFIG;
        const roleContext = USER_ROLE_CONTEXT.find(i => i.context === userContext);
        let endPoint = API_CONFIG.END_POINTS.UNIVERSITYSTAFF.APPLICATIONS.SINGLE_APPLICATION(applicationId);
        return await this.fetchData(
            endPoint,
            {
                method: 'PUT',
                headers: {
                    'Authorization': token
                },
                body: applicationsData,
                params: { current_role: roleContext.role }
            }
        );

    }

    /**
     * Fetches resume scholarship application data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    updateALLStaffApplication = async ({ token, applicationsData, userContext, options }) => {
        const { USER_ROLE_CONTEXT } = MAIN_CONFIG;
        const roleContext = USER_ROLE_CONTEXT.find(i => i.context === userContext);

        let endPoint = API_CONFIG.END_POINTS.UNIVERSITYSTAFF.APPLICATIONS.UPDATE_ALL_APPLICATION();
        const { search, filters, scholarship } = options;
        const searchParam = search ? { ...search } : {};
        const filtersParam = filters ? { application_filters: JSON.stringify(filters) } : {};
        const scholarshipParam = scholarship ? { scholarship__c: scholarship } : '';

        return await this.fetchData(
            endPoint,
            {
                method: 'PUT',
                headers: {
                    'Authorization': token
                },
                body: applicationsData,
                params: {
                    current_role: roleContext.role,
                    ...searchParam,
                    ...filtersParam,
                    ...scholarshipParam
                }
            }
        );

    }

    /**
     * Post resume scholarship application data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    ratingScoreSubmit = async ({ token, scholarshipId, applicationId, ratingScore }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.SCHOLARSHIPS.SUBMIT_APPLICATION_RATING(scholarshipId, applicationId),
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: { score: ratingScore }
            }
        );

    }

    /**
     * Post resume scholarship application data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    submitApplication = async ({ token, scholarshipId, applicationId, sendAccesscode, accessCode }) => {
        if (sendAccesscode) {
            return await this.fetchData(
                API_CONFIG.END_POINTS.APPLICANT.SCHOLARSHIPS.SUBMIT_APPLICATION(scholarshipId, applicationId),
                {
                    method: 'POST',
                    headers: {
                        'Authorization': token
                    },
                    body: {
                        access_code: accessCode
                    }
                }
            );
        } else {
            return await this.fetchData(
                API_CONFIG.END_POINTS.APPLICANT.SCHOLARSHIPS.SUBMIT_APPLICATION(scholarshipId, applicationId),
                {
                    method: 'POST',
                    headers: {
                        'Authorization': token
                    }
                }
            );
        }

    }

    /**
     * Delete ApplicationReference.
     * @param {string} id
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    deleteScholarshipReference = async ({ token, scholarshipId, applicationStepId, applicationId, referenceId, submitted_application_step }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.SCHOLARSHIPS.DELETE_REFERENCE(scholarshipId, applicationStepId, applicationId, referenceId, submitted_application_step),
            {
                method: 'DELETE',
                headers: {
                    'Authorization': token
                },
                body: { submitted_application_step: submitted_application_step }
            }
        );
    }

    /**
    * Delete ApplicationReference.
    * @param {string} id
    * @returns {Promise<{errorMessage: *, error: boolean}|*>}
    */
    fetchEsignDocumentUrl = async ({ token, scholarshipId, applicationId }) => {

        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.SCHOLARSHIPS.ESIGN_URL(scholarshipId, applicationId),
            {
                method: 'GET',
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    /**
     * Delete ApplicationIteration.
     * @param {string} id
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    deleteScholarshipIteration = async ({ token, scholarshipId, applicationStepId, applicationId, questionSetNumber, submitted_application_step }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.SCHOLARSHIPS.DELETE_ITERATION(scholarshipId, applicationStepId, applicationId, questionSetNumber),
            {
                method: 'DELETE',
                headers: {
                    'Authorization': token
                },
                body: { submitted_application_step: submitted_application_step }
            }
        );
    }

    /**
     * Add ApplicationDocument by Admin.
     * @param {string} id
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    AddApplicationDocument = async ({ token, applicationId, applicationData }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.ADMIN.ADD_DOCUMENT(applicationId),
            {
                method: 'PUT',
                headers: {
                    'Authorization': token
                },
                body: { application_data: applicationData }
            }
        );
    }
    /**
     * Delete ApplicationDocument by Admin.
     * @param {string} id
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    deleteApplicationDocument = async ({ token, scholarshipId, applicationStepId, applicationId, documentId, submitted_application_step = '' }) => {
        const requestBody = {};
        if (submitted_application_step !== '') {
            requestBody.submitted_application_step = submitted_application_step;
        }
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.SCHOLARSHIPS.DELETE_DOCUMENT(scholarshipId, applicationStepId, applicationId, documentId),
            {
                method: 'DELETE',
                headers: {
                    'Authorization': token
                },
                body:requestBody
            }
        );
    }

    /**
     * Fetches scholarship application data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchUnqieURL = async ({ token, param }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.SCHOLARSHIPS.UNIQUE_URL(),
            {
                method: 'GET',
                headers: {
                    'Authorization': token
                },
                params: { url_name__c: param }
            }
        );
    }

    /**
     * Fetches KFC data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    getKfcRestaurent = async ({ url, suggestType }) => {
        return await this.fetchData(
            url,
            {
                method: 'GET'
            }
        );
    }
    /**
     * Fetches KFC data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    getTacoBellAddress = async ({ url, suggestType }) => {
        return await this.fetchData(
            url,
            {
                method: 'GET'
            }
        );
    }
    /**
     * Fetches PUC data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    getPCUDetails = async ({ url, suggestType }) => {
        return await this.fetchData(
            url,
            {
                method: 'GET'
            }
        );
    }

    /**
     * Fetches School data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    getMatchingSchools = async ({ url, suggestType }) => {
        return await this.fetchData(
            url,
            {
                method: 'GET'
            }
        );
    }

    /**
     * Fetches School data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    getCountryList = async ({ url, suggestType }) => {
        return await this.fetchData(
            url,
            {
                method: 'GET'
            }
        );
    }
    /**
     * Fetches state data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    getStateList = async ({ url, suggestType }) => {
        return await this.fetchData(
            url,
            {
                method: 'GET'
            }
        );
    }
    /**
     * Fetches city data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    getCityList = async ({ url, suggestType }) => {
        return await this.fetchData(
            url,
            {
                method: 'GET'
            }
        );
    }
    /**
     * Fetches zip data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    getZipList = async ({ url, suggestType }) => {
        return await this.fetchData(
            url,
            {
                method: 'GET'
            }
        );
    }

    /**
     * Fetches Pizza Hut Store data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    getPizzaHutStore = async ({ url, suggestType }) => {
        return await this.fetchData(
            url,
            {
                method: 'GET'
            }
        );
    }

    /**
     * Fetches Financial Aid Location data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    getFinancialAid = async ({ url, suggestType }) => {
        return await this.fetchData(
            url,
            {
                method: 'GET'
            }
        );
    }    

    /**
     * Fetches place suggeation by google api data for apply
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    applicationFormPDF = async ({ token, applicationId }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.SCHOLARSHIPS.PRINT_PDF(applicationId),
            {
                method: 'GET',
                headers: {
                    'Authorization': token
                }
            }
        );
    }

    checkAccessCode = async ({ token, scholarshipId, code, applicationId }) => {

        return await this.fetchData(
            API_CONFIG.END_POINTS.APPLICANT.SCHOLARSHIPS.ACCESS_CODE_CHECK(scholarshipId),
            {
                method: 'POST',
                headers: {
                    'Authorization': token
                },
                body: {
                    access_code: code,
                    application_id: applicationId
                }
            }
        );
    }

    /**
     * Fetches all of the applications for a University Staff.
     * @param {string} token
     * @param {object} options
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    fetchApplicationsForUniversityStaff = async ({ token, options = {}, userContext }) => {

        const { page, search, sort, status, filters, scholarship } = options;
        const searchParam = search ? { ...search } : {};
        const sortParam = sort ? { order_with: sort } : {};
        const statusParam = status ? { application_status: status } : {};
        const filtersParam = filters ? { application_filters: JSON.stringify(filters) } : {};
        const scholarshipParam = scholarship ? { scholarship__c: scholarship } : '';

        return await this.fetchData(
            API_CONFIG.END_POINTS.UNIVERSITYSTAFF.APPLICATIONS.GET_ALL(),
            {
                headers: {
                    'Authorization': token
                },
                params: {
                    page: page || 1,
                    ...searchParam,
                    ...sortParam,
                    ...statusParam,
                    ...filtersParam,
                    ...scholarshipParam
                }
            }
        );
    }

    /**
     * Fetches promot user
     * @param {string} token
     * @returns {Promise<{errorMessage: *, error: boolean}|*>}
     */
    donorFeedBack = async ({ accountId, token, npsScore }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.ACCOUNTS.NPS_SCORE(accountId),
            {
                headers: {
                    'Authorization': token
                },
                method: 'POST',
                body: npsScore
            }
        );
    }

    unSubscribe = async ({  token , receiver_email }) => {
        return await this.fetchData(
            API_CONFIG.END_POINTS.UNSUBSCRIBE(receiver_email),
            {
                headers: {
                    'Authorization': token
                },
                method: 'PATCH',
            }
        );
    }


    handleLogoutAdmin = async () => {
        if (typeof window !== 'undefined') {
            document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
            document.cookie = 'admintoken=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
            document.cookie = 'context=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
            document.cookie = 'admincontext=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
            document.cookie = 'user=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
            document.cookie = 'adminuser=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
            document.cookie = 'currentAccount=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(() => {
                window.location.href = MAIN_CONFIG.URLS.LOGIN;
            }, 500);
        }
    }

    handleLogout = async (res = {}) => {
        if (typeof window !== 'undefined') {
            document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
            document.cookie = 'context=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
            document.cookie = 'user=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
            document.cookie = 'currentAccount=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
            //delete cookies for admin also
            document.cookie = 'admintoken=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
            document.cookie = 'admincontext=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
            document.cookie = 'adminuser=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
            localStorage.clear();
            sessionStorage.clear();
            setTimeout(() => {
                window.location.href = MAIN_CONFIG.URLS.LOGIN;
            },);
            // return false
        }
        // let customResponse={user:{},error:'Not Authorized'}
        // return res

    }
    hasXSSAttack = (content) => {
        const xssAttacks = [
          /javascript\s*:/gi,
          /on\w+\s*=/gi,
          /<\/?\s*script/gi,
          /alert/gi,
          /prompt\s*\(/gi,
          /window\s*\.\s*location\s*=/gi, 
        ];
        return xssAttacks.some((attack) => attack.test(content));
    };
    checkUrl (string) {
        const urlRegex = /^(?!.*(?:javascript|alert)\s*\().*(?:https?:\/\/|www\.|mailto:|tel:\d+).*$/i;
        try {
            return urlRegex.test(string);

        } catch (error) {
           return false; 
        }
      }
    sanitizeDeepJson = (jsonData) => {
        // Check if the current value is an object
        if (typeof jsonData === 'object' && jsonData !== null) {
            // Iterate over the properties
            for (const key in jsonData) {
                if (jsonData.hasOwnProperty(key)) {
                    // Check if the value is a string
                    if (typeof jsonData[key] === 'string') {
                        // Sanitize the HTML content
                        const decodedContent = he.decode(jsonData[key])
                        const sanitizedContent = sanitizeHtml(decodedContent, {
                            allowedTags:false,
                            allowedAttributes:false,
                            allowedIframeHostnames: allowedIframeHostnames,
                            allowVulnerableTags: true,
                            exclusiveFilter: (frame) => {
                                const { tag, attribs,text } = frame
                                if (tag === 'script') {
                                    return true;
                                  }
                                if(attribs){
                                    for (const attr in attribs) {
                                        const attrValue = attribs[attr];
                                        if ((urlAttr?.includes(attr))&&!this.checkUrl(attribs[attr])){
                                            return true
                                        }
                                        if (this.hasXSSAttack(attrValue)) {
                                        return true
                                        }
                                      }
                                }
                                if( text && this.hasXSSAttack(text)){
                                    return true
                                } 
                                return false             
                            },                 
                        })?.replace(/&amp;/g, '&')?.replace(/&lt;/g, "<")?.replace(/&gt;/g, ">")?.replace(/&quote;/g, `"`)
                        // Replace the original value with the sanitized one
                        jsonData[key] = sanitizedContent;
                    } else if (typeof jsonData[key] === 'object') {
                        // Recursively sanitize nested objects or arrays
                        this.sanitizeDeepJson(jsonData[key]);
                    }
                }
            }
        }
        return jsonData
    }
    /**
     * Fetches data from specified end-point.
     * @param {string} endPoint
     * @param {object} options
     * @returns {Promise<{errorMessage: *, error: boolean}|any>}
     */
    getMethodForCsrf = (method) => {
        const allowedMethods = ['put', 'post'];
        return method && allowedMethods.includes(method.toLowerCase())
    }
    fetchData = async (endPoint, options) => {
        const allowedMethods = ['put', 'post'];
        let method = options.method
        const genetateCsrfToken = method && allowedMethods.includes(method.toLowerCase())
        let csrfTokenValue = {};

        const fetchCSRFToken = async () => {
                const csrfResponse = await fetch(process.env.NEXT_PUBLIC_SERVER_URL + '/api/getCsrf', {
                method: 'get',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    'Content-Security-Policy': "frame-ancestors 'self' *.mykaleidoscope.com stage.gateway-testing.mykaleidoscope.com apply-dev-digital.herokuapp.com",
                    'X-Content-Security-Policy': "frame-ancestors 'self' *.mykaleidoscope.com stage.gateway-testing.mykaleidoscope.com apply-dev-digital.herokuapp.com",
                    'X-Frame-Options': "ALLOW-FROM *.mykaleidoscope.com stage.gateway-testing.mykaleidoscope.com apply-dev-digital.herokuapp.com",
                    'X-Content-Type-Options': 'nosniff',
                })
            });
            
            return csrfResponse.json();
        }

        const fetchApiData = async () => {
            const headers = {
                'Content-Type': 'application/json',
                'accept': 'application/json',
                'Content-Security-Policy': "frame-ancestors 'self' *.mykaleidoscope.com stage.gateway-testing.mykaleidoscope.com apply-dev-digital.herokuapp.com",
                'X-Content-Security-Policy': "frame-ancestors 'self' *.mykaleidoscope.com stage.gateway-testing.mykaleidoscope.com apply-dev-digital.herokuapp.com",
                'X-Frame-Options': "ALLOW-FROM *.mykaleidoscope.com stage.gateway-testing.mykaleidoscope.com apply-dev-digital.herokuapp.com",
                'X-Content-Type-Options': 'nosniff',
                ...options.headers
            }

            if (genetateCsrfToken) {
                headers['X-CSRF-Token'] = csrfTokenValue?.csrf;
            }

            const requestObj = {
                method: options.method || 'GET',
                headers
                
            };
            if (options.body) {
                requestObj.body = JSON.stringify(options.body);
            }

            const urlQuery = options.params ? `?${new URLSearchParams(options.params)}` : '';
            const endPointUrl = `${apiURL + endPoint}${urlQuery}`;

            try {
                const response = await fetch(endPointUrl, requestObj);
                var userAdminContext = cookies('context').admincontext
                var userContext = ""
                if (response.status === 401 && endPoint !== "/sign-in") {
                    this.handleLogout()
                    return false
                } else if (userAdminContext === 'ADMIN' && userContext === '' && response.status === 401) {
                    this.handleLogoutAdmin()
                    return false
                } else {
                    const jsonResponse = await (response).json();
                    // Check for pagination header data
                    const sanatisedData = this.sanitizeDeepJson(jsonResponse)
                    sanatisedData.pagination = {
                        currentPage: parseInt(response.headers.get('x-page'), 10),
                        totalRecords: parseInt(response.headers.get('x-total'), 10),
                        perPage: parseInt(response.headers.get('x-per-page'), 10)
                    };
                    return sanatisedData;
                }
            } catch (e) {
                return {
                    error: true,
                    errorMessage: e
                }
            }
        }

        if (genetateCsrfToken) {
          try {
            csrfTokenValue = await fetchCSRFToken();
          } catch {
            csrfTokenValue = { csrf: uuidv4() };
          } finally {
            let fetchedData = fetchApiData();
            return fetchedData;
          }
        } else {
          return fetchApiData();
        }
    }


    //  Churn Zero APi call for the token

    ChurnfetchData = async (token, accountId) => {

        return await this.fetchData(
            API_CONFIG.END_POINTS.DONORS.CHURNZERO_AUTH.GET_TOKEN(accountId),
            {
                headers: {
                    'Authorization': token
                }
            }
        );

    }

    /**
     * FormData from specified end-point and used for uploading File using FormData.
     * @param {string} endPoint
     * @param {object} options
     * @returns {Promise<{errorMessage: *, error: boolean}|any>}
     */
    FormDataPost = async (endPoint, options) => {
        const requestObj = {
            method: options.method || 'GET',
            headers: {
                ...options.headers
            }
        };

        if (options.body) {
            requestObj.body = options.body
        }

        const urlQuery = options.params ? `?${new URLSearchParams(options.params)}` : '';
        const endPointUrl = `${apiURL}${endPoint}${urlQuery}`;

        try {
            const response = await fetch(endPointUrl, requestObj);
            var userAdminContext = cookies('context').admincontext
            if (userAdminContext === 'ADMIN' && response.status === 401 && options.headers && options.headers.Authorization) {
                await this.handleLogout()
            } else {
                const jsonResponse = await (response).json();

                // Check for pagination header data
                jsonResponse.pagination = {
                    currentPage: parseInt(response.headers.get('x-page'), 10),
                    totalRecords: parseInt(response.headers.get('x-total'), 10),
                    perPage: parseInt(response.headers.get('x-per-page'), 10)
                };

                return jsonResponse;
            }
        } catch (e) {
            return {
                error: true,
                errorMessage: e
            }
        }

    }

}

export default KaleidoscopeAPI
